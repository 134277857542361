<template>
  <div class="mission-group-wrap">
    <ul class="mission-group">
      <li :class="['mission-item', isActive === index ? 'active' : '']" v-for="(item, index) in vessel" :key="index" @click.prevent="currentMission(index)">
        <div class="m-title-wrap">
          <span class="mission-name">
            <span class="title">任务名称:</span>
            {{ item.missionName }}
          </span>
        </div>
        <div class="details-control">
          <div class="details-wrap">
            <p>
              <span>创建人:</span>
              {{ item.createUser }}
            </p>
            <p v-if="item.updateUser">
              <span>更新人:</span>
              {{ item.updateUser }}
            </p>
            <p>
              <span>创建时间:</span>
              {{ item.createTime }}
            </p>
            <p v-if="item.updateUser">
              <span>更新时间:</span>
              {{ item.updateTime }}
            </p>
          </div>
          <div class="control-wrap" v-show="isActive === index">
            <el-tooltip :content="ctrlItem.title" placement="top" effect="light" v-for="(ctrlItem, index) in ctrls" :key="index">
              <i v-if="ctrlItem.isEL" style="padding-top: 8px;" class="control-item" :class="ctrlItem.icon" @click.stop="handleClick(ctrlItem.method, item)"></i>
              <i v-else :class="['control-item', 'iconfont', ctrlItem.icon]" @click.stop="handleClick(ctrlItem.method, item)"></i>
            </el-tooltip>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { getMissionBySiteId, getMissionDetail, undockMission } from '@/api/mission';
import statusInclude from '@/utils/statusCode';
export default {
  name: 'MissionGroup',
  props: ['query', 'stId'],
  data() {
    return {
      isActive: -1,
      ctrls: [
        {
          icon: 'icon-bianji',
          method: 'editMission',
          title: '编辑',
        },
        // {
        //     icon: 'icon-daochu',
        //     method: 'editMission',
        //     title: '导出',
        // },
        {
          icon: 'icon-fuzhi-copy',
          method: 'copyMission',
          title: '复制',
        },
        {
          icon: 'el-icon-download',
          isEL:true,
          method: 'exportMission',
          title: '导出',
        },
        {
          icon: 'icon-shanchutianchong',
          method: 'undockMission',
          title: '删除',
        },
      ],
      missionList: [],
      vessel: [],
      missionNameGroup: [],
    };
  },
  created() {
    this.getMissionList();
  },
  methods: {
    currentMission(i) {
      if (this.isActive == i) {
        this.isActive = -1;
        this.$emit('missionDetail', null);
      } else {
        this.isActive = i;
        this.getMissionDetail(this.vessel[i]);
      }
    },
    handleClick(method, data) {
      if (!method) {
        return this.$message({ type: 'warning', message: '此功能暂未开放' });
      }
      this[method](data);
    },
    hideActiveToolBar() {
      this.isActive = -1;
    },

    editMission(data) {
      this.$emit('editMission', data);
    },
    copyMission(data) {
      this.$emit('copyMission', data);
    },
    exportMission(data) {
      this.$emit('exportMission', data);
    },


    /**
     * @description: 删除航线信息
     * @param {*}
     * @return {*}
     * @author: 篆愁君
     */
    undockMission(data) {
      this.$confirm('此操作将删除航线信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code, reason } = await undockMission(data.missionID);
          if (statusInclude(code)) {
            this.$emit('clearMap');
            this.$message({ type: 'success', message: '删除成功' });
            this.getMissionList();
          } else {
            this.$message({ type: 'error', message: `删除失败${reason}` });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    async getMissionDetail(item) {
      if (item) {
        await getMissionDetail(item.missionID)
          .then((response) => {
            this.$emit('missionDetail', response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    async getMissionList(stId) {
      this.missionList = [];
      this.vessel = [];
      this.missionNameGroup = [];
      stId = stId ? stId : this.stId;
      const { data, code, reason } = await getMissionBySiteId(stId);
      if (statusInclude(code)) {
      } else {
        this.$message.error(reason);
      }
      this.missionList = data.list;
      this.vessel = data.list;
      this.missionNameGroup = data.list.map((mission) => mission.missionName);
      this.childEmit(this.missionNameGroup);
    },

    childEmit(data) {
      this.$emit('childEmit', data);
    },

    foo(type, data, e) {
      e.stopPropagation();
      this.$emit('foo', { type, data });
    },
  },
  watch: {
    query(newVal, oldVal) {
      let reg = new RegExp(newVal);
      if (this.stId) {
        this.vessel = this.missionList.filter((e) => e.siteID && e.missionName.match(reg));
      } else {
        this.vessel = this.missionList.filter((e) => e.missionName.match(reg));
      }
    },
    stId(newVal, oldVal) {
      if (this.query) {
        let reg = new RegExp(this.query);
        this.vessel = this.missionList.filter((e) => e.siteID && e.missionName.match(reg));
      } else {
        this.vessel = this.missionList;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mission-group-wrap {
  height: calc(100vh - 284px);
  font-size: 14px;
  font-family: 'Alibaba-PuHuiTi-Medium';
  .mission-group {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: auto;

    .mission-item {
      width: 100%;
      background: var(--main-el-mission-item-background);
      border-radius: 4px;
      border: 1px solid var(--main-border-color);
      padding: 6px;
      margin-bottom: 10px;
      color: var(--color-primary);
      cursor: pointer;
      .m-title-wrap {
        padding: 4px;
        border-radius: 2px;
        font-weight: 600;
        // background: var(--el-color-primary-light-9);
        color: var(--el-color-primary);
        .mission-name {
          font-family: 'Alibaba-PuHuiTi-Medium';
          color: #0073e7;
          .title {
            color: var(--el-color-primary);
            padding-right: 5px;
          }
        }
      }
      .details-control {
        .details-wrap {
          p:last-child {
            padding: 0;
            margin: 0;
          }
        }
        .control-wrap {
          display: flex;
          flex-direction: row;
          .control-item {
            margin: 10px 10px 0 0;
            padding: 4px;
            font-size: 18px;
            cursor: pointer;
            background: var(--el-fill-color-blank);
            border: 1px solid var(--main-border-color);
            border-radius: 4px;
          }
          .control-item:hover {
            color: var(--el-color-primary);
          }
        }
      }
      &:hover {
        background: var(--main-el-mission-item-hover);
      }
      &:focus {
        background: #f40;
      }
      &.active {
        background: #{$bDefault};
        // background: #f40;
      }
    }
    .mission-item:nth-last-child(1) {
      margin-bottom: 0;
    }
  }
  .mission-group::-webkit-scrollbar {
    display: none;
  }
}
</style>
