<template>
  <form class="upload-file-kml">
    <input type="file" id="fileInput" accept=".kml,.json,.kmz" />
  </form>
</template>

<script>
import TransformXML from 'x2js';
import JsZip from 'jszip';
import { midpoint } from '@turf/turf';
import { transform } from 'ol/proj';
export default {
  name: 'UploadFile',
  props: ['fileType'],
  data() {
    return {
      selectedFile: false,
      cameraNameText: ''
    };
  },
  watch: {
    fileType(t) {
      this.selectedFile = false;
      document.getElementById('fileInput').value = '';
    },

    selectedFile(t) {
      if (!t) {
        this.$emit('noFile');
      }
    }
  },
  methods: {
    isFileTypeValid(type) {
      let baseType = 'kml';
      if (this.fileType == 2) {
        baseType = 'json';
      }
      if (this.fileType == 4) {
        baseType = 'kmz';
      }
      return type == baseType;
    }
  },
  mounted() {
    let _that = this;
    document.getElementById('fileInput').addEventListener('change', function () {
      let types = this.files[0].name.split('.');
      let validFile = _that.isFileTypeValid(types[types.length - 1]);
      if (!validFile) {
        this.value = null;
        _that.selectedFile = false;
        return _that.$message.error('文件类型错误！');
      }
      // kmz
      if (_that.fileType == 4) {
        // 解压kmz
        let jszipObj = new JsZip();
        jszipObj
          .loadAsync(this.files[0])
          .then(
            function (zip) {
              Object.keys(zip.files).forEach(function (filename) {
                if (filename == 'wpmz/waylines.wpml' || filename == 'wpmz\\waylines.wpml') {
                  let file = zip.files[filename];
                  const transformXML = new TransformXML();
                  let kml = null;
                  zip.files[filename]
                    .async('string')
                    .then(function (fileData) {
                      kml = transformXML.xml2js(fileData);
                      let {
                        Document: {
                          Folder,
                          missionConfig
                        }
                      } = kml.kml;
                      if (Array.isArray(Folder)) {
                        [Folder] = Folder;
                      }
                      let  { Placemark, autoFlightSpeed, executeHeightMode } =Folder;
                      const iterationKMZ = pt => {
                        let point = {
                          altitude: parseFloat(pt.executeHeight),
                          latitude: parseFloat(pt.Point.coordinates.split(',')[1]),
                          longitude: parseFloat(pt.Point.coordinates.split(',')[0]),
                          gimbal: 0,
                          heading: 0,
                          gimbalPitch: 0,
                          speed: 1,
                          actions: []
                        };
                         if (pt?.gimbalPitchAngle?.__text) {
                            point.gimbalPitch = parseFloat(pt.gimbalPitchAngle.__text);
                          }
                        if (pt.waypointSpeed) {
                          point.speed = Number(pt.waypointSpeed.__text);
                        } else {
                          point.speed = parseFloat(autoFlightSpeed.__text);
                        }
                        if (pt.waypointHeadingParam?.waypointHeadingMode?.__text == 'followWayline') {
                          point.heading = -9999;
                        } else if (pt.waypointHeadingParam?.waypointHeadingAngle) {
                          point.heading = Number(Number(pt.waypointHeadingParam.waypointHeadingAngle.__text).toFixed(0));
                        }
                        if (pt?.actionGroup) {
                        let actionGroups = [];
                        if (Array.isArray(pt.actionGroup)) {
                          actionGroups = pt.actionGroup;
                        } else {
                          actionGroups = [pt.actionGroup];
                        }
                        let actions = [];
                        const actionTemp = [];
                        actionGroups.forEach((actionGroup) => {
                          if (actionGroup?.action) {
                            if (Array.isArray(actionGroup.action)) {
                              actions.push(...actionGroup.action);
                            } else {
                              actions.push(actionGroup.action);
                            }
                            actions.forEach((act) => {
                              const actionRes = actionKMZ(act, actionGroup?.actionTrigger);
                              if (Array.isArray(actionRes)) {
                                actionTemp.push(...actionRes);
                              } else {
                                actionTemp.push(actionRes);
                              }
                            });
                          }
                        });
                        actions = actionTemp.filter((act) => act.action != null);
                        point.actions = actions;
                      }
                        return point;
                      };
                      const actionKMZ = (action, actionTrigger) => {
                        const obj = {
                          action: null
                        };
                        const text = action.actionActuatorFunc.__text;

                        // 动作类型 1-悬停，2-开始录像，3-停止录像，4-云台俯仰 角调整，5-拍照，6-机身偏航角，7-变焦倍数
                        switch (text) {
                          case 'hover':
                            obj.action = 1;
                            obj.actionParams = parseFloat(action.actionActuatorFuncParam.hoverTime.__text);
                            break;
                          case 'startRecord':
                            obj.action = 2;
                            break;
                          case 'stopRecord':
                            obj.action = 3;
                            break;
                          //  case 'gimbalEvenlyRotate': //动作为航段间均匀转动云台pitch角
                          // case 'customDirName':创建新文件夹
                          case 'gimbalRotate':
                            debugger;
                            const gimbalActions = [];
                            // 偏航
                            if (action.actionActuatorFuncParam.gimbalYawRotateEnable.__text === '1') {
                              if (action.actionActuatorFuncParam.gimbalRotateMode.__text === 'relativeAngle') {
                                // 相对于飞行器机头的角度
                                gimbalActions.unshift({
                                  action: 9, // 云台航偏角相对调整
                                  actionParams: Number(parseFloat(action.actionActuatorFuncParam.gimbalYawRotateAngle.__text).toFixed(2))
                                });
                              } else {
                                gimbalActions.unshift({
                                  action: 8, // 云台航偏角绝对调整
                                  actionParams: Number(parseFloat(action.actionActuatorFuncParam.gimbalYawRotateAngle.__text).toFixed(2))
                                });
                              }
                            }
                            // 俯仰
                            if (action.actionActuatorFuncParam.gimbalPitchRotateEnable.__text === '1') {
                              gimbalActions.unshift({
                                action: 4, // 云台俯仰
                                actionParams: Number(parseFloat(action.actionActuatorFuncParam.gimbalPitchRotateAngle.__text).toFixed(2))
                              });
                            }
                            // 云台横滚
                            // if (action.actionActuatorFuncParam.gimbalRollRotateEnable.__text === '1') {
                            //   gimbalActions.unshift({
                            //     action: 88888, // 云台横滚
                            //     actionParams: Number(parseFloat(action.actionActuatorFuncParam.gimbalRollRotateAngle.__text).toFixed(2))
                            //   });
                            // }
                            return gimbalActions;
                          case 'takePhoto':
                          case 'accurateShoot':
                          case 'orientedShoot':
                            const aircraftHeading = action.actionActuatorFuncParam?.aircraftHeading?.__text;
                            const gimbalYawRotateAngle = action.actionActuatorFuncParam?.gimbalYawRotateAngle?.__text;
                            const gimbalPitchRotateAngle = action.actionActuatorFuncParam?.gimbalPitchRotateAngle?.__text;
                            const focalLength = action.actionActuatorFuncParam?.focalLength?.__text;
                            const accurateCameraType = action.actionActuatorFuncParam?.accurateCameraType?.__text;
                            const arr = [];
                            // 判断是否是间隔拍照
                            if (actionTrigger) {
                              const actionTriggerType = actionTrigger.actionTriggerType?.__text;
                              const actionTriggerParam = actionTrigger.actionTriggerParam?.__text;
                              if (actionTriggerType === 'multipleTiming') { // 等时触发
                                arr.push({
                                  action: 10, // 等时拍照
                                  actionParams: parseInt(parseFloat(actionTriggerParam).toFixed(2))
                                });
                              } else if (actionTriggerType === 'multipleDistance') { // 等距触发
                                arr.push({
                                  action: 11, // 等距拍照
                                  actionParams: Number(parseFloat(actionTriggerParam).toFixed(2))
                                });
                              } else {
                                arr.push({
                                  action: 5 // 拍照
                                });
                              }
                            }else {
                                arr.push({
                                  action: 5 // 拍照
                                });
                              }

                            if (focalLength) {
                              let zoomValue = 0;
                              if (['42', '43', '61'].includes(accurateCameraType)) {
                                zoomValue = Number(((parseFloat(focalLength) * 4) / (3 * 31.7)).toFixed(2));
                              } else {
                                zoomValue = Number((parseFloat(focalLength) / 24).toFixed(2));
                              }
                              arr.unshift({
                                action: 7, // 变焦
                                actionParams: zoomValue
                              });
                            }
                            if (gimbalPitchRotateAngle) {
                              arr.unshift({
                                action: 4, // 云台俯仰
                                actionParams: Number(parseFloat(gimbalPitchRotateAngle).toFixed(2))
                              });
                            }
                            if (gimbalYawRotateAngle) {
                              arr.unshift({
                                action: 8, // 云台航偏角绝对调整
                                actionParams: Number(parseFloat(gimbalYawRotateAngle).toFixed(2))
                              });
                            }
                            if (aircraftHeading) {
                              arr.unshift({
                                action: 6, // 机身偏航
                                actionParams: Number(parseFloat(aircraftHeading).toFixed(2))
                              });
                            }

                            return arr;
                          case 'rotateYaw':
                            // action.actionActuatorFuncParam.aircraftPathMode.__text : clockwise：顺时针旋转 ,counterClockwise：逆时针旋转
                            obj.action = 6;
                            obj.actionParams = Number(parseFloat(action.actionActuatorFuncParam.aircraftHeading.__text).toFixed(2));
                            break;
                          case 'zoom':
                            obj.action = 7;
                            // wpml:payloadEnumValue:
                            // 42（机型：H20）,
                            // 43（机型：H20T）,
                            // 61（机型：H20N）,
                            // 50（机型：P1）,
                            // 52（机型：M30双光相机）,
                            // 53（机型：M30T三光相机）,
                            // 90742（机型：L1）
                            /* eslint no-case-declarations: 0 */
                            let actionParams = Number(parseFloat(action.actionActuatorFuncParam.focalLength.__text).toFixed(2));
                            if (['42', '43', '61'].includes(missionConfig.payloadInfo.payloadEnumValue.__text)) {
                              actionParams = Number(((parseFloat(actionParams) * 4) / (3 * 31.7)).toFixed(2));
                            } else {
                              actionParams = Number((parseFloat(actionParams) / 24).toFixed(2));
                            }
                            actionParams = actionParams < 2 ? 2 : actionParams;
                            obj.actionParams = actionParams;
                            break;
                          default:
                            break;
                        }
                        return obj;
                      };
                      if (!Array.isArray(Placemark)) {
                        Placemark = [Placemark];
                      }
                      let flightParams = {};
                      flightParams.cruiseSpeed = parseFloat(autoFlightSpeed.__text);
                      flightParams.cruiseAltitude = 120;
                      flightParams.cruiseGimbal = -45;
                      flightParams.cruiseGimbalPitch = -45;
                      flightParams.GCSType = 1;
                      flightParams.flightPath = Placemark.map(iterationKMZ);
                      _that.$emit('readFile', flightParams);
                    })
                    .catch(function (error) {
                      console.error(error);
                      _that.$message.error('解析异常！');
                    });
                }
              });
            },
            function (e) {
              console.error(e);
              _that.$message.error('解析异常: ' + f.name + ': ' + e.message);
            }
          )
          .catch(function (error) {
            console.error(error);
          });
        return;
      }
      readFile(this, function (data) {
        _that.selectedFile = true;
        const transformXML = new TransformXML();
        let JSONFile = null;
        if (_that.fileType == 1 || _that.fileType == 3) {
          JSONFile = transformXML.xml2js(data);
        } else if (_that.fileType == 2) {
          JSONFile = JSON.parse(data);
        }
        console.log(JSONFile);

        const addActionsBeforePreciseShot = (actionsTasrget, preciseShotInfo, shotIndex) => {
          debugger;
          // 动作类型 1-悬停，2-开始录像，3-停止录像，4-云台俯仰 角调整，5-拍照，6-机身偏航角，7-变焦倍数
          let gimbalZoom = 1,
            gimbalPitch = 0,
            gimbalYaw = 0;
          if (Array.isArray(preciseShotInfo.preciseInfo) && preciseShotInfo.preciseInfo[shotIndex]) {
            // 多个
            let target = preciseShotInfo.preciseInfo[shotIndex];
            gimbalZoom = parseFloat(target.focalDistance.__text);
            gimbalPitch = parseFloat(target.gimbalPitch.__text);
            gimbalYaw = parseFloat(target.gimbalYaw.__text);
            if (['42', '43', '61'].includes(target.cameraType.__text)) {
              gimbalZoom = Number((((parseFloat(gimbalZoom) / 10) * 4) / (3 * 31.7)).toFixed(2));
            } else {
              gimbalZoom = Number((parseFloat(gimbalZoom) / 10 / 24).toFixed(2));
            }
          } else {
            gimbalZoom = parseFloat(preciseShotInfo.preciseInfo.focalDistance.__text);
            gimbalPitch = parseFloat(preciseShotInfo.preciseInfo.gimbalPitch.__text);
            gimbalYaw = parseFloat(preciseShotInfo.preciseInfo.gimbalYaw.__text);
            if (['42', '43', '61'].includes(preciseShotInfo.preciseInfo.cameraType.__text)) {
              gimbalZoom = Number((((parseFloat(gimbalZoom) / 10) * 4) / (3 * 31.7)).toFixed(2));
            } else {
              gimbalZoom = Number((parseFloat(gimbalZoom) / 10 / 24).toFixed(2));
            }
          }
          // 云台俯仰
          actionsTasrget.push({
            index: actionsTasrget.length + 1,
            action: 4,
            actionParams: gimbalPitch
          });
          // 偏航
          actionsTasrget.push({
            index: actionsTasrget.length + 1,
            action: 6,
            actionParams: gimbalYaw
          });
          // 变焦
          actionsTasrget.push({
            index: actionsTasrget.length + 1,
            action: 7,
            actionParams: gimbalZoom
          });

          // 拍照
          actionsTasrget.push({
            index: actionsTasrget.length + 1,
            action: 5
          });
        };

        const iterationAction = (actions, preciseShotInfo) => {
          if (!actions) {
            return [];
          }
          if (!Array.isArray(actions)) {
            actions = [actions];
          }
          let actionsTasrget = [];
          // 记录PreciseShot
          let preciseShotCount = 0;
          actions.forEach((action, index) => {
            let obj = {
              index: index + 1,
              action: 1
            };
            //动作类型 1-悬停，2-开始录像，3-停止录像，4-云台俯仰 角调整，5-拍照，6-机身偏航角，7-变焦倍数
            switch (action.__text) {
              case 'Hovering': // 子曦平台
                obj.action = 1;
                obj.actionParams = parseFloat(action._param);
                obj.actionParams = obj.actionParams / 1000;
                break;

              case 'AircraftYaw':
                obj.action = 6;
                obj.actionParams = parseFloat(action._param);
                if (action._accuracy && action._accuracy == '1') {
                  obj.actionParams = obj.actionParams / 10;
                }
                break;
              case 'GimbalYaw':
                obj.action = 6;
                obj.actionParams = parseFloat(action._param);
                if (action._accuracy && action._accuracy == '1') {
                  obj.actionParams = obj.actionParams / 10;
                }
                break;
              case 'GimbalPitch':
                obj.action = 4;
                obj.actionParams = parseFloat(action._param);
                if (action._accuracy && action._accuracy == '1') {
                  obj.actionParams = obj.actionParams / 10;
                }
                break;

              case 'ShootPhoto':
                obj.action = 5;
                break;
              case 'PreciseShot':
                obj.action = 5;
                addActionsBeforePreciseShot(actionsTasrget, preciseShotInfo, preciseShotCount);
                ++preciseShotCount;
                obj = null;
                break;
              // case 'GimbalYaw':
              //   obj.action = 6;
              //   obj.actionParams = parseFloat(action._param) / 10;
              //   break;
              case 'CameraZoom':
                obj.action = 7;
                if (_that.cameraNameText.includes('H20')) {
                  obj.actionParams = Number((((parseFloat(action._param) / 10) * 4) / (3 * 31.7)).toFixed(2));
                } else {
                  obj.actionParams = Number((parseFloat(action._param) / 10 / 24).toFixed(2));
                }
                break;
            }
            if (obj) {
              actionsTasrget.push(obj);
            }
          });

          return actionsTasrget;
        };
        let i = 0;
        const iterationParam = uObj => {
          if (!uObj.ExtendedData) {
            uObj.ExtendedData = {};
          }
          const {
            ExtendedData: { actions = [], preciseShotInfo = null, speed = null, gimbalPitch = 0, heading = 0 },
            Point: { coordinates }
          } = uObj;
          const [longitude, latitude, altitude] = coordinates.split(',');
          const [lng, lat] = [parseFloat(longitude), parseFloat(latitude)];
          const misPoint = {
            index: i++,
            altitude: parseFloat(altitude),
            latitude: lat,
            longitude: lng,
            speed: 5,
            gimbal: parseFloat(gimbalPitch.__text),
            heading: parseFloat(heading.__text),
            gimbalPitch: parseFloat(gimbalPitch.__text),
            actions: iterationAction(actions, preciseShotInfo)
          };
          return misPoint;
        };

        const iterationParam2 = coordinates => {
          const [longitude, latitude, altitude] = coordinates;
          const [lng, lat] = [parseFloat(longitude), parseFloat(latitude)];
          const misPoint = {
            index: i2++,
            altitude: parseFloat(altitude),
            latitude: lat,
            longitude: lng,
            gimbal: -45,
            gimbalPitch: -45,
            heading: 0,
            actions: []
          };
          return misPoint;
        };

        let i2 = 0;
        const flightParams = {};
        let payloadconfig = null;
        if (_that.fileType == 1 || _that.fileType == 2) {
          let Folder = JSONFile.kml.Document.Folder;

          if (Array.isArray(Folder)) {
            flightParams.flightPath = Folder[1].Placemark.LineString.coordinates
              .split(/\s+/)
              .map(e => e.split(',').map(Number))
              .map(iterationParam2);
            flightParams.cruiseSpeed = 5;
            flightParams.cruiseAltitude = 120;
            flightParams.cruiseGimbal = -45;
            flightParams.cruiseGimbalPitch = -45;
          } else {
            if (JSONFile.kml.Document.Placemark && Array.isArray(JSONFile.kml.Document.Placemark)) {
              flightParams.flightPath = JSONFile.kml.Document.Placemark.map(iterationParam);
            } else {
              payloadconfig = JSONFile.kml.Document.Placemark ? JSONFile.kml.Document.Placemark : null;
              flightParams.flightPath = Folder.Placemark.map(iterationParam);
            }
            if (!JSONFile.kml.Document.Placemark.ExtendedData) {
              JSONFile.kml.Document.Placemark.ExtendedData = { altitude: 0, autoFlightSpeed: 0, droneInfo: { droneCameras: { camera: { cameraName: {} } } } };
            }

            const {
              kml: {
                Document: {
                  Placemark: {
                    ExtendedData: { altitude = { __text: '' }, autoFlightSpeed = { __text: '' }, droneInfo: { droneCameras: { camera: { cameraName = {} } = {} } } = {} }
                  }
                }
              }
            } = JSONFile;
            _that.cameraNameText = cameraName.__text ?? '';
            flightParams.cruiseSpeed = parseFloat(autoFlightSpeed.__text);
            flightParams.cruiseAltitude = altitude.__text ? parseFloat(altitude.__text) : 120;
            flightParams.cruiseGimbal = -45;
            flightParams.cruiseGimbalPitch = -45;
            flightParams.GCSType = 1;
          }
        } else if (_that.fileType == 3) {
          console.log(JSONFile);
          // 选点任务
          flightParams.flightPath = JSONFile.kml.Document.Placemark.LineString.coordinates
            .split(/\s+/)
            .map(e => e.split(',').map(Number))
            .map(iterationParam2);

          flightParams.cruiseSpeed = 5;
          flightParams.cruiseAltitude = 120;
          flightParams.cruiseGimbal = -45;
          flightParams.cruiseGimbalPitch = -45;
        }

        _that.$emit('readFile', flightParams);
      });
    });

    function readFile(input, sc, ec) {
      //支持chrome IE10
      if (window.FileReader) {
        let file = input.files[0];
        let filename = file.name.split('.')[0];
        let reader = new FileReader();
        reader.onload = function () {
          sc(this.result); //这里的 this 指向 FileReader
        };
        reader.readAsText(file);
      } else if (document.implementation && document.implementation.createDocument) {
        //支持FF
        let xmlDoc;
        xmlDoc = document.implementation.createDocument('', '', null);
        xmlDoc.async = false;
        xmlDoc.load(input.value);
        sc(xmlDoc);
      } else {
        ec();
      }
    }
  }
};
</script>

<style></style>
