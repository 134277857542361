import Vue from 'vue';

export default {
  data() {
    return {
      action: '',
      activeMenu: [
        { name: '悬停', type: 1, action: 1 },
        { name: '开始录像', type: 2, action: 2 },
        { name: '停止录像', type: 2, action: 3 },
        { name: '云台俯仰角', type: 1, action: 4 },
        { name: '拍照', type: 2, action: 5 },
        { name: '偏航角', type: 1, action: 6 },
        { name: '变焦', type: 1, action: 7 },
        { name: '云台航偏角绝对调整', type: 1, action: 8 },
        { name: '云台航偏角相对调整', type: 1, action: 9 },
        { name: '开始等时间隔拍照', type: 1, action: 10 },
        { name: '开始等距间隔拍照', type: 1, action: 11 },
        { name: '停止间隔拍照', type: 2, action: 12 },
      ],
      actionIndex: 1,
    };
  },
  methods: {
    setMarkerAction(type) {
      const motion = JSON.parse(JSON.stringify(this.activeMenu[type - 1]));
      const { action } = motion;
      const actions = this.marker.actions ? this.marker.actions : [];
      if (actions.length >= 8) return this.$message({ type: 'warning', message: '动作数量超限' });
      switch (action) {
        case 1:
          motion.min = 5;
          motion.max = 60; //5分钟
          motion.unit = 's';
          motion.actionParams = 10;
          break;
        case 2:
          break;
        case 3:
          break;
        case 4:
          motion.min = -90;
          motion.max = 0;
          motion.actionParams = -45;
          motion.unit = '°';
          break;
        case 5:
          break;
        case 6:
          motion.min = -180;
          motion.max = 180;
          motion.actionParams = 0;
          motion.unit = '°';
          break;
        case 7:
          motion.min = 1;
          motion.max = 30;
          motion.actionParams = 5;
          motion.unit = '倍';
          break;
        case 8:
          motion.min = -180;
          motion.max = 180;
          motion.actionParams = 0;
          motion.unit = '°';
          break;
        case 9:
          motion.min = -180;
          motion.max = 180;
          motion.actionParams = 0;
          motion.unit = '°';
          break;
        case 10:
          motion.min = 1;
          motion.max = 30;
          motion.actionParams = 1;
          motion.unit = '秒';
          break;
        case 11:
          motion.min = 1;
          motion.max = 100;
          motion.actionParams = 1;
          motion.unit = '米';
          break;
        case 12:
          motion.min = 1;
          motion.max = 30;
          motion.actionParams = 1;
          motion.unit = '';
          break;
        default:
          break;
      }
      motion.key = new Date().getTime().toString() + Math.random() * 1000;
      motion.index = this.actionIndex;
      this.actionIndex++;
      actions.push(motion);
      this.marker.actions = actions;
      this.marker.actions.forEach((action, index) => (action.index = index)); //重置index 消除删除后index对不齐的问题
      return this.marker;
    },
  },
};
