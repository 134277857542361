<template>
  <div class="action-menu-wrapper">
    <span>添加动作：</span>
    <el-select size="mini" v-model="action" @input="setMarkerAction(action)">
      <el-option :value="item.action" :label="item.name" v-for="(item, index) in activeMenu" :key="index"></el-option>
    </el-select>
  </div>
</template>

<script>
import Mixin from '../mixin';
export default {
  name: 'ActionMenu',
  mixins: [Mixin],
  props: ['marker'],
};
</script>

<style lang="scss" scoped>
.action-menu-wrapper {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  .el-select {
    width: 160px;
  }
}
</style>
