<template>
  <el-form class="mission-form-wrapper" :model="missionForm" :rules="rules" ref="ruleForm" label-width="100px">
    <el-form-item label="任务站点" prop="siteID">
      <el-row>
        <el-col :span="16">
          <el-cascader style="width: 100%" v-model="cascaderValue" :props="{ lazy: true, lazyLoad: lazyLoad }" @change="cascaderValueChange"></el-cascader>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item label="文件类型" v-if="isUploadFile">
      <el-select v-model="fileType">
        <el-option label="KML" :value="1"></el-option>
        <el-option label="KMZ" :value="4"></el-option>
        <el-option label="JSON" :value="2"></el-option>
        <el-option label="选点任务" :value="3"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="导入任务" v-if="isUploadFile">
      <UploadFile :fileType="fileType" @readFile="readFile" @noFile="noFileHandler" />
    </el-form-item>
    <el-form-item label="任务类型" prop="missionType">
      <el-row>
        <el-col :span="16">
      <el-select style="width: 100%"  v-model="missionForm.missionType">
        <el-option :value="1" label="路点巡航">
          
          </el-option>
          <el-option :value="2" label="二维扫图"></el-option>
          <!-- <el-option :value="3" label="倾斜摄影">
          
          </el-option> -->
      </el-select>
      </el-col>
      </el-row>
        
    </el-form-item>
    <el-form-item label="任务名称" prop="missionName">
      <el-row>
        <el-col :span="16">
          <el-input v-model="missionForm.missionName"></el-input>
        </el-col>
      </el-row>
    </el-form-item>
    <el-form-item>
      <el-button @click="resetForm('ruleForm')">重 置</el-button>
      <el-button type="primary" @click="puttingMissionInfo('ruleForm')">确定</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import UploadFile from './UploadFile.vue';
import { queryProjectListByCpID } from '@/api/project.js';
import { getSiteGroup } from '@/api/site';
export default {
  name: 'MissionForm',
  props: ['uId', 'missionNameGroup', 'CPList', 'isUploadFile'],
  components: { UploadFile },
  data() {
    const isMissionName = (value) => {
      const reg = /[a-zA-Z0-9-\u4E00-\u9FFF_-]{5,26}$/;
      return reg.test(value) ? true : false;
    };
    const isRepeated = (name) => this.missionNameGroup.findIndex((e) => e === name);

    const isMissionRightfulvar = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('任务名称不能为空'));
      }
      if (!isMissionName(value)) {
        callback(new Error('请输入5位以上不包含特殊字符的任务名称'));
      } else if (isRepeated(value) !== -1) {
        callback(new Error('任务名称重复'));
      } else {
        callback();
      }
    };
    return {
      fileType: 1,
      selectedFile: false,
      vessel: [],
      projectList: [],
      siteList: [],
      cascaderValue: '',
      missionForm: {
        cpId: '',
        pId: '',
        siteID: '',
        missionName: '',
        missionType: 1,
        Gimbal1Type: 'H20T',
        UAVModel: 'M300',
      },

      rules: {
        // cpId: [{ required: true, message: '请选择企业', trigger: 'change' }],
        // pId: [{ required: true, message: '请选择项目', trigger: 'change' }],
        siteID: [{ required: true, message: '请选择站点', trigger: 'change' }],
         missionType: [{ required: true, message: '请选择任务类型', trigger: 'change' }],
        // Gimbal1Type: [{ required: true, message: '请选择负载相机', trigger: 'change' }],
        // UAVModel: [{ required: true, message: '请选择无人机型号', trigger: 'change' }],
        missionName: [{ required: true, validator: isMissionRightfulvar, trigger: 'blur' }],
      },
      droneModes: ['M300', 'M200V2'],
      cameras: ['H20', 'H20T'],
    };
  },

  watch: {},
  methods: {
    clearCascaderValue() {
      this.cascaderValue = '';
      this.missionForm.missionName = '';
    },
    async lazyLoad(node, resolve) {
      const { level, root } = node;
      console.log(node);
      let nodes = [];
      if (this.CPList.length == 1) {
        if (root) {
          let res = await queryProjectListByCpID(this.CPList[0].cpId);
          if (res?.data) {
            for (let item of res.data) {
              let node = { value: item.pId, label: item.pName, leaf: false };
              nodes.push(node);
            }
          } else {
          }
        } else {
          if (level == 1) {
            let res = await getSiteGroup(1, 500, node.value);
            if (res?.data?.list) {
              this.siteList = res.data.list;
              for (let item of res.data.list) {
                let node = { value: item.siteID, label: item.siteName, leaf: true };
                nodes.push(node);
              }
            } else {
              this.siteList = [];
            }
          } else {
          }
        }
      } else {
        if (root) {
          for (let item of this.CPList) {
            let node = { value: item.cpId, label: item.cpName, leaf: false };
            nodes.push(node);
          }
        } else {
          if (level == 1) {
            let res = await queryProjectListByCpID(node.data.value);
            if (res?.data) {
              for (let item of res.data) {
                let node = { value: item.pId, label: item.pName, leaf: false };
                nodes.push(node);
              }
            } else {
            }
          } else if (level == 2) {
            let res = await getSiteGroup(1, 500, node.value);
            if (res?.data?.list) {
              this.siteList = res.data.list;
              for (let item of res.data.list) {
                let node = { value: item.siteID, label: item.siteName, leaf: true };
                nodes.push(node);
              }
            } else {
              this.siteList = [];
            }
          } else {
          }
        }
      }

      resolve(nodes);
    },

    cascaderValueChange(v) {
      if (v.length > 0) {
        if (v.length == 2) {
          this.missionForm.pId = v[0];
          this.missionForm.siteID = v[1];
        } else {
          this.missionForm.cpId = v[0];
          this.missionForm.pId = v[1];
          this.missionForm.siteID = v[2];
        }
      }
      let list = [];
      for (let site of this.siteList) {
        if (site.siteID != this.missionForm.siteID) {
          list.push(site);
        }
      }
      this.$emit('siteData', list);
    },

    // 通过调用resolve将子节点数据返回，通知组件数据加载完成
    async getProjectList(cpId) {
      this.projectList = [];
      const res = await queryProjectListByCpID(cpId);
      //   if (res?.data?.list) {
      //     this.projectList = res.data.list
      //   }
      if (res?.data) {
        this.projectList = res.data;
      }
    },

    readFile(flightParams) {
      debugger
      this.selectedFile = true;
      this.$emit('readFile', flightParams);
    },

    noFileHandler() {
      this.selectedFile = false;
      this.$emit('noFile');
    },
    puttingMissionInfo(formName) {
      if (this.isUploadFile && !this.selectedFile) {
        this.$message.error('请选择文件！');
        return;
      }
      let mission = {};
      this.$refs[formName].validate((valid) => {
        if (valid) {
          mission = this.missionForm;
        } else {
          mission = false;
          return false;
        }
      });
      return this.$emit('puttingMissionInfo', { mission, type: 0 });
    },
    resetForm(formName) {
      this.missionForm.siteLongitude = '';
      this.missionForm.siteLatitude = '';
      this.cascaderValue = '';
      this.missionForm.cpId = '';
      this.missionForm.pId = '';
      this.missionForm.siteID = '';
      this.$refs[formName].resetFields();
    },
  },
  created() {
    if (this.CPList.length == 1) {
      this.missionForm.cpId = this.CPList[0].cpId;
    }
  },
  mounted() {},
};
</script>

<style scoped></style>
