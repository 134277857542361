<template>
  <div class="mission-to-download"></div>
</template>

<script>
export default {
  name: 'MissionDownload',
  data() {
    return {
    
    };
  },
  watch: {
    
  },
  methods: {
   
     jsonDown(mission){
      let aDom=  document.createElement('a');
      aDom.download=`${mission.missionName}.json`;
       aDom.href=URL.createObjectURL(new Blob([JSON.stringify(mission)]));
      document.body.appendChild(aDom);
      aDom.click();
      setTimeout(()=>{
        document.body.removeChild(aDom);
      },500)
    },
    kmlDown(mission){
      let aDom=  document.createElement('a');
      aDom.download=`${mission.missionName}.kml`;
      let points=mission?.flightParams?.flightPath?mission.flightParams.flightPath:[];
      let lngLatAltarrStr =points.reduce(pt=>`${pt.longitude},${pt.latitude},${pt.altitude}`,'');

      let placeMarks = points.reduce(this.toPlaceMark,``);
      let xmlStringStart = this.getXmlStart(mission.missionName);
      let xmlStringEnd =this.getXmlEnd(mission.flightParams,lngLatAltarrStr);

      aDom.href=URL.createObjectURL(new Blob([xmlStringStart+placeMarks+xmlStringEnd]));
      document.body.appendChild(aDom);
      aDom.click();
      setTimeout(()=>{
        document.body.removeChild(aDom);
      },500)
    },
    toPlaceMarkAction(prev,act){
      let actonObj={
        6 :'AircraftYaw', //   6 :'GimbalYaw',
        4 :'GimbalPitch',
        7 :'CameraZoom',
        5 :'ShootPhoto',
      }
      if(act&&act.action&&actonObj[act.acton]){
        let param = obj.actionParams?obj.actionParams:0;
        return prev+`<mis:actions param="${param}" accuracy="0" cameraIndex="0" payloadType="0" payloadIndex="0">${actonObj[act.acton]}</mis:actions>`
      }
      return '';
    },
  toPlaceMark(prev,point,index){
    let {longitude,latitude,altitude,speed,gimbalPitch,heading,actions=[]} =point;
    actions=actions||[];
    let useWaylineHeadingMode = heading===-9999;
    let actionsTag=actions.reduce(this.toPlaceMarkAction,``);
    return prev+
      `
            <Placemark>
                <name>Waypoint${++index}</name>
                <visibility>1</visibility>
                <description>Waypoint</description>
                <styleUrl>#waypointStyle</styleUrl>
                <ExtendedData xmlns:mis="www.dji.com">
                  <mis:useWaylineAltitude>false</mis:useWaylineAltitude>
                  <mis:heading>${heading}</mis:heading>
                  <mis:turnMode>Auto</mis:turnMode>
                  <mis:gimbalPitch>${gimbalPitch}</mis:gimbalPitch>
                  <mis:useWaylineSpeed>true</mis:useWaylineSpeed>
                  <mis:speed>${speed}</mis:speed>
                  <mis:useWaylineHeadingMode>${useWaylineHeadingMode}</mis:useWaylineHeadingMode>
                  <mis:useWaylinePointType>true</mis:useWaylinePointType>
                  <mis:pointType>LineStop</mis:pointType>
                  <mis:headingMode>UsePointSetting</mis:headingMode>
                  <mis:cornerRadius>0.2</mis:cornerRadius>${actionsTag}
                </ExtendedData>
                <Point>
                  <altitudeMode>relativeToGround</altitudeMode>
                  <coordinates>${longitude},${latitude},${altitude}</coordinates>
                </Point>
            </Placemark>`;
  },
  getXmlEnd(flightParams,lngLatAltarrStr){
    const {cruiseAltitude,cruiseSpeed} =flightParams;
    return `
  </Folder>
    <Placemark>
        <name>Wayline</name>
        <description>Wayline</description>
        <visibility>1</visibility>
        <ExtendedData xmlns:mis="www.dji.com">
          <mis:altitude>${cruiseAltitude}</mis:altitude>
          <mis:autoFlightSpeed>${cruiseSpeed}</mis:autoFlightSpeed>
          <mis:actionOnFinish>GoHome</mis:actionOnFinish>
          <mis:headingMode>UsePointSetting</mis:headingMode>
          <mis:gimbalPitchMode>ControlledByRC</mis:gimbalPitchMode>
          <mis:powerSaveMode>false</mis:powerSaveMode>
          <mis:flightCali>false</mis:flightCali>
          <mis:waypointType>LineStop</mis:waypointType>
          <mis:droneInfo>
            <mis:droneType>PM430</mis:droneType>
            <mis:droneCameras>
              <mis:camera>
                <mis:cameraIndex>0</mis:cameraIndex>
                <mis:cameraName>Zenmuse H20T</mis:cameraName>
                <mis:cameraType>43</mis:cameraType>
                <mis:payloadCameraType>65535</mis:payloadCameraType>
              </mis:camera>
            </mis:droneCameras>
            <mis:droneHeight>
              <mis:useAbsolute>false</mis:useAbsolute>
              <mis:hasTakeoffHeight>false</mis:hasTakeoffHeight>
              <mis:takeoffHeight>0.0</mis:takeoffHeight>
            </mis:droneHeight>
          </mis:droneInfo>
        </ExtendedData>
        <styleUrl>#waylineGreenPoly</styleUrl>
        <LineString>
          <tessellate>1</tessellate>
          <altitudeMode>relativeToGround</altitudeMode>
          <coordinates>${lngLatAltarrStr}</coordinates>
        </LineString>
      </Placemark>
    </Document>
  </kml>
    `
  },

  getXmlStart(title){
  return   `<?xml version="1.0" encoding="UTF-8"?>
      <kml xmlns="http://www.opengis.net/kml/2.2">
        <Document xmlns="">
          <name>${title}</name>
          <open>1</open>
          <ExtendedData xmlns:mis="www.dji.com">
            <mis:type>Waypoint</mis:type>
            <mis:stationType>0</mis:stationType>
          </ExtendedData>
          <Style id="waylineGreenPoly">
            <LineStyle>
              <color>FF0AEE8B</color>
              <width>6</width>
            </LineStyle>
          </Style>
          <Style id="waypointStyle">
            <IconStyle>
              <Icon>
                <href>https://cdnen.dji-flighthub.com/static/app/images/point.png</href>
              </Icon>
            </IconStyle>
          </Style>
          <Folder>
            <name>Waypoints</name>
            <description>Waypoints in the Mission.</description>`;
  },
  },
 
};
</script>

<style></style>
