<template>
  <el-form class="select-form-wrapper" label-position="left" label-width="70px">
    <el-form-item label="任务名称">
      <el-input v-model="missionName" @input="retrieve"></el-input>
    </el-form-item>
    <el-form-item label="任务站点" prop="siteID">
      <el-cascader clearable v-model="cascaderValue" :props="{ lazy: true, lazyLoad: lazyLoad }" @change="cascaderValueChange"></el-cascader>
    </el-form-item>
  </el-form>
</template>

<script>
import { queryProjectListByCpID } from '@/api/project.js';
import { getSiteGroup } from '@/api/site';
import { getUserCorporationList } from '@/api/role';
import { getUserInformation } from '@/utils/auth.js';
import statusInclude from '../../../utils/statusCode';
export default {
  name: 'LookUpMission',
  data() {
    return {
      siteList: [],
      cascaderValue: '',
      siteID: '',
      missionName: '',
      missionType:1,
      projectID: '',
      mapname: '',
      cpId: '',
      pId: '',
      siteID: '',
      CPList: [],
    };
  },

  methods: {
    async lazyLoad(node, resolve) {
      const { level, root } = node;
      console.log(node);
      let u = getUserInformation();
      if (u && u.userInformation && u.userInformation.userId) {
        this.userId = u.userInformation.userId;
        let { code, reason, data } = await getUserCorporationList();
        if (statusInclude(code)) {
          this.CPList = data;
        }
      }
      let nodes = [];
      if (this.CPList.length == 1) {
        if (root) {
          let res = await queryProjectListByCpID(this.CPList[0].cpId);
          if (res?.data) {
            for (let item of res.data) {
              let node = { value: item.pId, label: item.pName, leaf: false };
              nodes.push(node);
            }
          } else {
          }
        } else {
          if (level == 1) {
            let res = await getSiteGroup(1, 500, node.value);
            if (res?.data?.list) {
              this.siteList = res.data.list;
              for (let item of res.data.list) {
                let node = { value: item.siteID, label: item.siteName, leaf: true };
                nodes.push(node);
              }
            } else {
              this.siteList = [];
            }
          } else {
          }
        }
      } else {
        if (root) {
          for (let item of this.CPList) {
            let node = { value: item.cpId, label: item.cpName, leaf: false };
            nodes.push(node);
          }
        } else {
          if (level == 1) {
            let res = await queryProjectListByCpID(node.data.value);
            if (res?.data) {
              for (let item of res.data) {
                let node = { value: item.pId, label: item.pName, leaf: false };
                nodes.push(node);
              }
            } else {
            }
          } else if (level == 2) {
            let res = await getSiteGroup(1, 500, node.value);
            if (res?.data?.list) {
              this.siteList = res.data.list;
              for (let item of res.data.list) {
                let node = { value: item.siteID, label: item.siteName, leaf: true };
                nodes.push(node);
              }
            } else {
              this.siteList = [];
            }
          } else {
          }
        }
      }

      resolve(nodes);
    },
    cascaderValueChange(v) {
      if (v.length > 0) {
        if (v.length == 2) {
          this.pId = v[0];
          this.siteID = v[1];
        } else {
          this.cpId = v[0];
          this.pId = v[1];
          this.siteID = v[2];
        }
      } else {
        this.siteID = '';
      }
      this.$emit('stId', this.siteID);
    },
    retrieve(query) {
      this.$emit('retrieve', query);
    },
  
  },
};
</script>

<style lang="scss">
.select-form-wrapper {
  .el-form-item:last-child {
    margin-bottom: 10px;
  }
}
</style>
