<template>
  <div class="map2d-base">
    <div :id="mapId" class="map-2d" />
    <div class="base-box">
      <div class="base-wrapper">
        <!-- <a-tooltip placement="left">
          <template slot="title">
            <span>截图</span>
          </template>
          <div class="shot common">
            <img src="/map/shot.png">
          </div>
        </a-tooltip> -->
        <a-tooltip placement="left">
          <template slot="title">
            <span>图层</span>
          </template>
          <div class="layer common" @click="layerFlag=!layerFlag">
            <img src="@/assets/images/mission/map/layer.png">
          </div>
        </a-tooltip>
        <a-tooltip placement="left">
          <template slot="title">
            <span>定位</span>
          </template>
          <div class="location common">
            <img src="@/assets/images/mission/map/location.png">
          </div>
        </a-tooltip>
        <a-tooltip placement="left">
          <template slot="title">
            <span>放大</span>
          </template>
          <div class="zoomin common" @click="zoomIn">
            <img src="@/assets/images/mission/map/zoomin.png">
          </div>
        </a-tooltip>
        <a-tooltip placement="left">
          <template slot="title">
            <span>缩小</span>
          </template>
          <div class="zoomout common" @click="zoomOut">
            <img src="@/assets/images/mission/map/zoomout.png">
          </div>
        </a-tooltip>
        <div v-show="layerFlag" class="layer-box">
          <div class="base-layer-box">
            <div class="title">
              地图底图
            </div>
            <div class="layer-group">
              <a-checkbox-group
                v-model="currentBaseLayer"
                :options="baseLayers"
              />
              <span slot="label" slot-scope="{value}" class="layer">
                {{ value }}
              </span>
            </div>
            <!-- <a-radio-group
              v-model="currentBaseLayer"
              class="layer-group"
              name="radioGroup"
            >
              <a-radio v-for="item in baseLayers" :key="item.value" class="layer" :value="item.value">
                {{ item.label }}
              </a-radio>
            </a-radio-group> -->
            <!-- <div class="layer-group">
              <a-checkbox-group
                v-model="currentBaseLayer"
                :options="baseLayers"
              />
              <span slot="label" slot-scope="{value}" class="layer">
                {{ value }}
              </span>
            </div> -->
          </div>
          <!-- <div class="base-layer-box">
            <div class="title">
              自定义图层
            </div>
            <div class="layer-group">
              <a-checkbox-group
                v-model="currentCustomLayer"
                :options="customLayers"
              />
              <span slot="label" slot-scope="{value}" class="layer">
                {{ value }}
              </span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import { LAYER} from './../map.config.js';

let map = null;
let tileGaodeSatellite = null;
let tileGaodeRoadlite = null;
let tileGaodeStreetslite = null;
const baseTiles = {
  gaodeSatellite: {
    url: LAYER.GD_WX,
    leaflet: {
      subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
      maxZoom: 18
    }
  },
  gaodeStreetslite: {
    url: LAYER.GD_JD,
    leaflet: {
      subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
      maxZoom: 18
    }
  },
  gaodeRoadlite: {
    url: LAYER.GD_LW,
    leaflet: {
      subdomains: ['0', '1', '2', '3', '4', '5', '6', '7'],
      maxZoom: 18
    }
  }
};

export default {
  props: {
    mapId: {
      type: String,
      default: 'map',
      required: false
    }
  },
  data() {
    const baseLayers = [
      {
        label: '高德卫星',
        value: 'gaodeSatellite'
      },
      {
        label: '高德路网',
        value: 'gaodeRoadlite'
      },
      {
        label: '高德普通',
        value: 'gaodeStreetslite'
      }
    ];
    return {
      layerFlag: false,
      baseLayers,
      currentBaseLayer: ['gaodeSatellite']
    };
  },
  watch: {
    currentBaseLayer(v) {
      this.toggleBaseLayer(v);
    }
  },
  beforeDestroy() {
    if (tileGaodeStreetslite) {
      map[this.mapId].removeLayer(tileGaodeStreetslite);
      tileGaodeStreetslite = null;
    }
    if (tileGaodeStreetslite) {
      map[this.mapId].removeLayer(tileGaodeStreetslite);
      tileGaodeStreetslite = null;
    }
    map.remove();
    map = null;
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      map = L.map(this.mapId, {
        minZoom: 1,
        maxZoom: 18,
        zoomControl: false,
        attributionControl: false,
        measureControl: true,
        zoomSnap: 0,
        zoom: 15
      });
      map.on('load', () => {
        map.createPane('baseLayers');
        map.getPane('baseLayers').style.zIndex = 0;
        map.getPane('baseLayers').style.pointerEvents = 'none';

        this.toggleBaseLayer(this.currentBaseLayer);
      });
      map.setView({
        lat: 31.774169,
        lng: 121.224288
      }, 15);
      this.$root.$emit('mapOnfinished', { L, map });
    },
    toggleBaseLayer(value) {
      if (value.findIndex((v) => v === 'gaodeSatellite') === -1) {
        this.removeGaodeSatellite();
      } else {
        this.removeGaodeSatellite();
        this.addGaodeSatellite();
      }
      if (value.findIndex((v) => v === 'gaodeRoadlite') === -1) {
        this.removeGaodeRoadlite();
      } else {
        this.removeGaodeRoadlite();
        this.addGaodeRoadlite();
      }
      if (value.findIndex((v) => v === 'gaodeStreetslite') === -1) {
        this.removeGaodeStreetslite();
      } else {
        this.removeGaodeStreetslite();
        this.addGaodeStreetslite();
      }
    },
    addGaodeRoadlite() {
      tileGaodeRoadlite = L.tileLayer(baseTiles.gaodeRoadlite.url, { ...baseTiles.gaodeRoadlite.leaflet, pane: 'baseLayers' });
      map.addLayer(tileGaodeRoadlite);
    },
    removeGaodeRoadlite() {
      if (tileGaodeRoadlite) {
        map.removeLayer(tileGaodeRoadlite);
        tileGaodeRoadlite = null;
      }
    },
    addGaodeSatellite() {
      tileGaodeSatellite = L.tileLayer(baseTiles.gaodeSatellite.url, { ...baseTiles.gaodeSatellite.leaflet, pane: 'baseLayers' });
      map.addLayer(tileGaodeSatellite);
    },
    removeGaodeSatellite() {
      if (tileGaodeSatellite) {
        map.removeLayer(tileGaodeSatellite);
        tileGaodeSatellite = null;
      }
    },
    addGaodeStreetslite() {
      tileGaodeStreetslite = L.tileLayer(baseTiles.gaodeStreetslite.url, { ...baseTiles.gaodeStreetslite.leaflet, pane: 'baseLayers' });
      map.addLayer(tileGaodeStreetslite);
    },
    removeGaodeStreetslite() {
      if (tileGaodeStreetslite) {
        map.removeLayer(tileGaodeStreetslite);
        tileGaodeStreetslite = null;
      }
    },
    zoomIn() {
      map.zoomIn();
    },
    zoomOut() {
      map.zoomOut();
    }
  }
};
</script>

<style src="leaflet/dist/leaflet.css"></style>
<style lang="scss" scoped>
$color-check: #36C6FB;
$color-layer-bg: rgba(18, 18, 18, 0.85);
$color-layer:#A1A1A1;

/deep/.ant-checkbox-wrapper{
  display: block;
  padding: 6px 0;
  color: $color-layer;
  &:hover{
    color: $color-check;
    .ant-checkbox-checked{
      &::after{
        border-color: $color-check;
      }
    }
  }
  &.ant-checkbox-wrapper-checked{
    color: $color-check;
  }
  .ant-checkbox-inner{
    background-color: $color-layer-bg;
    border-color: $color-layer;
  }
  .ant-checkbox-checked{
    &::after{
      border-color: $color-layer;
    }
    .ant-checkbox-inner{
      border-color: $color-check;
      &::after{
        border-color: $color-check;
      }
    }
  }
}
/deep/.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
  border-color: $color-check;
}
.map2d-base{
  height: 100%;
  position: relative;
  .map-2d{
    height: 100%;
  }
  .base-box{
    position: absolute;
    z-index: 400;
    right: 28px;
    bottom: 28px;
    transition: right 0.5s;
    .base-wrapper{
      position: relative;
      .base-layer-box{
        width: 100%;
      }
    }
    .common{
      margin-bottom: 10px;
      cursor: pointer;
    }
    img{
      width: 28px;
    }
    .layer-box{
      background: $color-layer-bg;
      border: 1px solid #6A6A6A;
      border-radius: 5px;
      padding: 16px 0 10px;
      position: absolute;
      top: 0;
      right: 54px;
      width: 142px;
      min-width: 126px;
      .title{
        border-left: 2px solid $color-check;
        padding-left: 10px;
        color: #ffffff;
      }
      .layer-group{
        padding: 6px 0 6px 12px;
        .layer{
          display: block;
          padding: 6px 0;
        }
      }
      // 区域定位样式
      .layer.child{
        padding-left: 30px;
      }
      /deep/.ant-checkbox-wrapper{
        font-size: 14px;
      }
    }
  }
}
</style>
