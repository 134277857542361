<template>
  <el-form class="mission-top-bar-wrap">
    <el-tooltip content="关闭" effect="light" placement="bottom">
      <i class="iconfont icon-guanbi" @click="$emit('close')"></i>
    </el-tooltip>
    <el-form-item>
      <el-input v-model="mission.name" @change="emitMissionName(mission.name)"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'TopBar',
  props: ['missionName'],
  data() {
    return {
      mission: {
        name: '',
      },
    };
  },
  created() {
    this.mission.name = this.missionName;
  },
  methods: {
    emitMissionName(name) {
      this.$emit('emitMissionName', name);
    },
  },
};
</script>

<style lang="scss" scoped>
.mission-top-bar-wrap {
  width: 280px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid var(--main-border-color);
  > i {
    font-size: 20px;
    text-align: center;
    width: 40px;
    line-height: 40px;
    background: var(--el-fill-color-blank);
    color: var(--color-primary);
    border-radius: 4px;
  }
  i:hover {
    background: #{$blue};
  }
  ::v-deep .el-form-item {
    margin-bottom: 0;
  }
  input[name='missionName'] {
    width: 80%;
    border-radius: 0;
    background: var(--el-fill-color-blank);
    border: 1px solid var(--main-border-color);
    height: 40px;
    padding-left: 10px;
  }

  > label {
    width: 18%;
    height: 40px;
    line-height: 40px;
    background: var(--el-fill-color-blank);
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }

  label:active {
    background: #0050b3;
    color: #4ab7bd;
  }
}
</style>
