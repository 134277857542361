<template>
  <div class="param-toolbar-wrap">
    <ul class="toolbar">
      <li class="toolbar-item" v-for="(item, index) in toolList" :key="index" @click="calledFn(item.methods, item.value)">
        <el-tooltip class="item" effect="light" :content="item.label" placement="top-start"><i :class="[item.icon, 'iconfont']"></i></el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ParamFunction',
  data() {
    return {
      toolList: [
        {
          icon: 'icon-baocun',
          label: '保存',
          value: 'save',
          methods: 'switchingModel',
        },
        {
          icon: 'icon-guanbi',
          label: '删除路点',
          value: 'delete',
          methods: 'deleteMissionMarker',
        },
      ],
    };
  },
  methods: {
    calledFn(fn, item) {
      this[fn](item);
    },
    deleteMissionMarker(instruction) {
      this.sendInstruction(instruction);
    },

    switchingModel(instruction) {
      this.sendInstruction(instruction);
    },
    sendInstruction(instruction) {
      console.log(instruction);
      this.$emit('sendInstruction', instruction);
    },
  },
};
</script>

<style lang="scss" scoped>
.param-toolbar-wrap {
  width: 280px;
  margin: 0 0 10px 0;
  background: var(--el-fill-color-blank);
  border-bottom: 1px solid var(--main-border-color);
  > ul.toolbar {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;

    > li.toolbar-item {
      padding: 6px;
      margin: 0 0 0 4px;
      color: var(--color-primary);
      font-size: 16px;
      .el-tooltip:hover {
        color: #409eff;
      }
      .el-tooltip:active {
        color: #409eff;
      }
    }
    li.toolbar-item:hover {
      cursor: pointer;
    }
  }
}
</style>
