<template>
  <el-row class="coordinates-wrapper">
    <el-col>
      经度:<input ref="lng" type="text" :value="longitude" @change="setValue(longitude,'lng')">
    </el-col>
    <el-col>
      纬度:<input ref="lat" type="text" :value="latitude" @change="setValue(latitude,'lat')">
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'InputPosition',
  props: ['longitude', 'latitude'],
  methods: {
    setValue(coordinates, key) {
      this.$emit('setValue', { coordinates, key })
    }
  },
}
</script>

<style lang='scss' scoped>
.coordinates-wrapper {
}
</style>