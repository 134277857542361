<template>
  <div class="active-item-wrap">
    <div class="sky-top-bar">
      <i :class="['el-icon-s-operation', isEdit ? 'move ' : '']" style="padding: 10px"></i>
      <div class="sky-title">
        <span class="active-name">{{ name }}</span>
        <span class="active-value" v-if="type === 1">{{ value }}</span>
        <span class="active-unit" v-if="type === 1">{{ unit }}</span>
      </div>
      <i class="del-icon el-icon-delete" @click="deleteAction(index)" style="padding: 10px"></i>
    </div>
    <div class="adjust-slider-bar skysys-slider-wrap" v-if="type === 1 && isEdit">
      <el-button size="mini" icon="el-icon-minus" @click="reduce"></el-button>
      <input class="slider-value" type="range" v-model="v" :min="min" :max="max" @input="setValue(v)" />

      <el-button size="mini" icon="el-icon-plus" @click="increase"></el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActiveItem',
  props: ['name', 'min', 'max', 'type', 'unit', 'index', 'value', 'isEdit'],
  data() {
    return {
      v: 5,
    };
  },

  created() {
    this.v = this.value;
  },
  watch: {
    value(v) {
      this.v = v;
    },
  },

  mounted() {
    this.activeSlider();
  },

  updated() {
    this.activeSlider();
  },
  methods: {
    activeSlider() {
      const el = document.querySelectorAll('.slider-value');
      el?.forEach((e) => {
        e?.addEventListener('mouseover', () => {
          this.setEnable(true);
        });

        e?.addEventListener('mouseout', () => {
          this.setEnable(false);
        });
      });
    },
    deleteAction(i) {
      this.$emit('deleteAction', i);
    },
    reduce() {
      if (this.v > this.min) {
        this.v -= 1;
        this.$emit('setValue', { index: this.index, v: this.v });
      }
    },
    setValue(v) {
      this.v = parseInt(v);
      this.$emit('setValue', { index: this.index, v: this.v });
    },
    increase() {
      if (this.v < this.max) {
        this.v += 1;
        this.$emit('setValue', { index: this.index, v: this.v });
      }
    },
    setEnable(isEnable) {
      this.$emit('setEnable', isEnable);
    },
  },
};
</script>

<style lang="scss" scoped>
.active-item-wrap {
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px;
  background: var(--main-el-table-background);
  border-bottom: 1px solid var(--main-border-color);
  color: var(--color-primary);
  .sky-top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    align-items: center;
    .move {
      cursor: move;
    }
    .sky-title {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }
    .del-icon {
      cursor: pointer;
    }
    .value-area {
      span {
        display: inline-block;
      }
      .value {
        width: 44px;
        padding: 2px 12px;
        text-align: center;
        // background: #313131;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
      }
      .unit {
        width: 30px;
        text-align: center;
      }
    }
  }
  .adjust-slider-bar {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .el-button {
      font-size: 12px;
      padding: 0 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
