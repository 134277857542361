import Vue from 'vue';

import {
  Button, Input, Tooltip, Checkbox, Slider, Icon, Select, Switch, Carousel, Row, Col, Modal, Upload, Spin
} from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

Vue.use(Input)
  .use(Button)
  .use(Tooltip)
  .use(Checkbox)
  .use(Slider)
  .use(Icon)
  .use(Select)
  .use(Switch)
  .use(Carousel)
  .use(Row)
  .use(Col)
  .use(Modal)
  .use(Upload)
  .use(Spin);
