<template>
  <div class="area">
     <button v-if="missionID" class="back" style="right:85px;" @click="del">删除</button>
       <button class="back" @click="back()">返回</button>
    <div v-if="loading" class="shot-loading">
      <a-spin size="large" />
      <span>正在生成缩略图，请稍等~~</span>
    </div>
    <div :class="['modal-left', shrinkLeft ? 'modal-shrink' : '']">
      <div class="common-header">
        <div class="dot" />
        <div class="title">二维扫图</div>
      </div>
      <img v-if="!shrinkLeft" class="shrink" src="@/assets/images/mission/task/shrink.png" @click="shrinkLeft = !shrinkLeft" />
      <img v-else class="shrink" src="@/assets/images/mission/task/expand.png" @click="shrinkLeft = !shrinkLeft" />
      <div class="task-mainer x-scroll">
        <div class="task-name">
          <a-input v-model="flyparams.missionName">
            <img slot="suffix" src="@/assets/images/mission/task/edit.png" alt="" />
          </a-input>
        </div>
        <div class="task-icon-module">
          <div class="module">
            <div class="icon">
              <img src="@/assets/images/mission/task/area.png" />
            </div>
            <div class="text">
              {{ area }}
            </div>
            <div class="unit">面积({{ areaUnit }})</div>
          </div>
          <div class="module">
            <div class="icon">
              <img src="@/assets/images/mission/task/distance.png" />
            </div>
            <div class="text">
              {{ distance }}
            </div>
            <div class="unit">飞行里程(km)</div>
          </div>
          <div class="module">
            <div class="icon">
              <img src="@/assets/images/mission/task/flytime.png" />
            </div>
            <div class="text">
              {{ flyTime }}
            </div>
            <div class="unit">飞行时间(min)</div>
          </div>
        </div>
        <div class="task-icon-module">
          <div class="module">
            <div class="icon">
              <img src="@/assets/images/mission/task/gsd.png" />
            </div>
            <div class="text">
              {{ resolutionRatio }}
            </div>
            <div class="unit">CM/PX</div>
          </div>
          <div class="module">
            <div class="icon">
              <img src="@/assets/images/mission/task/camera.png" />
            </div>
            <div class="text">
              {{ photoNumber }}
            </div>
            <div class="unit">拍照张数(张)</div>
          </div>
          <div class="module">
            <div class="icon">
              <img src="@/assets/images/mission/task/flynumber.png" />
            </div>
            <div class="text">1</div>
            <div class="unit">飞行架次(次)</div>
          </div>
        </div>
        <div class="task-line-module">
          <div class="label-module">
            <img src="@/assets/images/mission/task/cameratype.png" />
            <span class="label">相机型号</span>
          </div>
          <div class="filter-box">
            <a-select v-model="flyparams.cameraModel" style="width: 100%" dropdown-class-name="black-theme">
              <template slot="suffixIcon">
                <a-icon type="caret-down" />
              </template>
              <a-select-option v-for="(item, index) in cameraList" :key="index" :value="item.value">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="task-line-module">
          <div class="label-module">
            <img src="@/assets/images/mission/task/shootmode.png" />
            <span class="label">拍照模式</span>
          </div>
          <div class="filter-box">
            <a-select v-model="flyparams.THMode" style="width: 100%" dropdown-class-name="black-theme">
              <template slot="suffixIcon">
                <a-icon type="caret-down" />
              </template>
              <a-select-option v-for="(item, index) in shootModeList" :key="index" :value="item.value">
                {{ item.text }}
              </a-select-option>
            </a-select>
          </div>
          <!-- <div class="shoot-inner">
            <span class="label">间隔时间</span>
            <span class="text">{{ flyparams.THModeParam }}s</span>
          </div> -->
        </div>
        <div class="num-c">
          <PlanSlider :value="flyparams.cruiseSpeed" title="飞行速度" unit="m/s" :icon="require('@/assets/images/mission/task/speed.png')" :min="2" :max="15" :step="1" @change="sliderChangeEvent($event, 'cruiseSpeed')" />
          <PlanSlider :value="flyparams.cruiseAltitude" title="飞行高度" unit="m" :icon="require('@/assets/images/mission/task/height.png')" :min="20" :max="500" :step="1" @change="sliderChangeEvent($event, 'cruiseAltitude')" />
          <PlanSlider :value="flyparams.takeOffAltitude" title="起飞点高度" unit="m" :icon="require('@/assets/images/mission/task/height.png')" :min="0" :max="flyparams.cruiseAltitude - 5" :step="0.1" @change="sliderChangeEvent($event, 'takeOffAltitude')" />
          <PlanSlider :value="flyparams.longitudinalOverlapRate" title="航向重叠率" unit="%" :icon="require('@/assets/images/mission/task/portraitoverlap.png')" :min="10" :max="99" :step="1" @change="sliderChangeEvent($event, 'longitudinalOverlapRate')" />
          <PlanSlider :value="flyparams.lateralOverlapRate" title="旁向重叠率" unit="%" :icon="require('@/assets/images/mission/task/overlaprate.png')" :min="10" :max="99" :step="1" @change="sliderChangeEvent($event, 'lateralOverlapRate')" />
          <PlanSlider :value="flyparams.FRouteRMAngle" title="主航线角度" unit="°" :icon="require('@/assets/images/mission/task/wayangle.png')" :min="-180" :max="180" :step="1" @change="sliderChangeEvent($event, 'FRouteRMAngle')" />
          <!-- <div class="task-line-module">
            <div class="label-module">
              <img src="@/assets/images/mission/task/caremadirection.png">
              <span class="label">相机朝向</span>
            </div>
            <div class="filter-box">
              <a-select v-model="flyparams.cruiseUAVYaw" style="width: 100%" dropdown-class-name="black-theme">
                <template slot="suffixIcon">
                  <a-icon type="caret-down" />
                </template>
                <a-select-option v-for="(item, index) in cameraDirectionList" :key="index" :value="item">
                  {{ item }}°
                </a-select-option>
              </a-select>
            </div>
          </div> -->
          <PlanSlider :value="flyparams.cruiseGimbalPitch" title="云台俯仰角度" unit="°" :icon="require('@/assets/images/mission/task/gimbalpitch.png')" :min="-90" :max="0" :step="1" @change="sliderChangeEvent($event, 'cruiseGimbalPitch')" />
          <!-- <div v-if="startEndFlag" class="task-line-module take-off">
            <div class="label-module">
              <img src="@/assets/images/mission/task/exchange.png">
              <span class="label">起始点交换</span>
            </div>
            <a-switch default-checked @change="changeStartEnd" />
          </div> -->
          <!-- <div v-if="startEndFlag" class="finish-c">
            <div class="finish-title">
              <div class="finish-name">
                <span>起</span>
                <span class="change-icon">
                  <a-icon style="color: #337dfe;font-weight:bold" type="swap" @click="changeStartEnd" />
                </span>
                <span>降</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="x-icon-btn" @click="submitTask">
        <img src="@/assets/images/mission/task/submit.png" />
        <span>提交任务</span>
      </div>
    </div>
    <div class="map-custom">
      <Map />
    </div>
  </div>
</template>

<script>
import '@/plugins/antd-ui';
import $ from 'jquery';
import { featureCollection, point, lineString } from '@turf/helpers';
import { lineIntersect, bearing, distance, rhumbDestination, center, polygon as turfPolygon, area as turfArea, midpoint } from '@turf/turf';
import coordtransform from '@/utils/coordtransform.js'; //坐标转换
import Map from '@/components/maps/leaflet';
import PlanSlider from '../components/PlanSlider.vue';
import { getSiteDetail } from '@/api/site';
import statusInclude from '@/utils/statusCode';
import { getMissionDetail, insertMission, updateMission ,undockMission} from '@/api/mission';
let L = null;
let map = null;
let pointsArr = []; // 点坐标
let markerPointList = [];
let anchorPointList = [];
let anchorPoints = [];
let lineLayer = null;
let hangerLine = null;
let pointIcon = null;
let anchorIcon = null;
let delIcon = null;
let startIcon = null;
let endIcon = null;
let moveIcon = null;
let hangarIcon = null;
let moveTip = null;
let pointIndex = -1; // 当前操作点
let addIndex = -1;
let crossLine = null;
let delBtn = null;
let startPoint = null;
let endPoint = null;
let hangarMarker = null;
let takeOff = null;
let land = null;
let crossPointsList = [];

export default {
  props: {
    info: {
      type: Object,
      default: {}
    }
  },
  components: {
    Map,
    PlanSlider
  },
  data() {
    return {
      shrinkLeft: false,
      // hangarPoint: [31.776624, 121.218542],
      hangarPoint: [],
      totalDistance: 0,
      areaUnit: '㎡',
      showFocus: false,
      myFocus: 20,
      loading: false,
      cameraList: [
        {
          value: 'H20T WIDE',
          text: 'H20T 广角',
          sizewidth: 6.29,
          sizeheight: 4.71,
          resolutionwidth: 4056,
          resolutionheight: 3040,
          focuslength: 24
        },
        {
          value: 'H20T IR',
          text: 'H20T 红外',
          sizewidth: 7.68,
          sizeheight: 6.144,
          resolutionwidth: 640,
          resolutionheight: 512,
          focuslength: 58
        },
        {
          value: '御2行业进阶 WIDE',
          text: '御2行业进阶 可见光',
          sizewidth: 6.4,
          sizeheight: 4.8,
          resolutionwidth: 8000,
          resolutionheight: 6000,
          focuslength: 24
        },
        {
          value: '御2行业进阶 IR',
          text: '御2行业进阶 红外',
          sizewidth: 7.68,
          sizeheight: 6.144,
          resolutionwidth: 640,
          resolutionheight: 512,
          focuslength: 38
        }
        // {
        //   value: 'XT2',
        //   text: 'XT2',
        //   sizewidth: 7.68,
        //   sizeheight: 6.144,
        //   resolutionwidth: 640,
        //   resolutionheight: 512,
        //   focuslength: 38
        // },
        // {
        //   value: 'M30T',
        //   text: 'M30T',
        //   sizewidth: 7.68,
        //   sizeheight: 6.144,
        //   resolutionwidth: 640,
        //   resolutionheight: 512,
        //   focuslength: 38
        // }
      ],
      dragging: false,
      area: 0,
      distance: 0,
      flyTime: 0,
      photoNumber: 0,
      resolutionRatio: 0,
      cameraDirectionList: [],
      shootModeList: [
        {
          text: '航点悬停拍照',
          value: 1
        }
        // {
        //   text: '等距间隔拍照',
        //   value: 2
        // },
        // {
        //   text: '等时间隔拍照',
        //   value: 3
        // }
      ],
      flyparams: {
        missionName: '新建多边形飞行任务',
        cameraModel: 'H20T WIDE', // 相机型号
        THMode: 1, // 拍照模式：1-航点悬停、2-等距间隔、3-等时间隔
        THModeParam: 0,
        cruiseSpeed: 10,
        cruiseAltitude: 100,
        longitudinalOverlapRate: 10,
        lateralOverlapRate: 10,
        FRouteRMAngle: 90,
        cruiseGimbalPitch: -90,
        eq35mm: true,
        sideSpace: 100,
        headSpace: 10,
        waymode: 'scan',
        takeOffAltitude: 0
      },
      startEndFlag: false,
      switchFlag: false,
      newModal: false,
      subTaskNum: 1,
      siteID: '',
      missionID: '',
      flightPathPoint: [],
      // taskSplitIndex: [],
      realWidth: 0
    };
  },
  computed: {},
  watch: {
    // eslint-disable-next-line
    'flyparams.cruiseSpeed': function () {
      this.getFlightTime();
      // this.getShootTime();
    },
    // eslint-disable-next-line
    'flyparams.cameraModel': function () {
      this.crossLineDraw();
    },
    // eslint-disable-next-line
    'flyparams.cruiseAltitude': function () {
      this.crossLineDraw();
    },
    // eslint-disable-next-line
    'flyparams.takeOffAltitude': function () {
      this.crossLineDraw();
    },
    // eslint-disable-next-line
    'flyparams.FRouteRMAngle': function (v) {
      this.crossLineDraw();
    },
    // eslint-disable-next-line
    'flyparams.lateralOverlapRate': function () {
      this.crossLineDraw();
    },
    // eslint-disable-next-line
    'flyparams.longitudinalOverlapRate': function () {
      this.crossLineDraw();
    }
  },
  beforeMount() {
    if (this.info.siteID) {
      this.siteID = this.info.siteID;
    }
    if (this.info.missionID) {
      this.missionID = this.info.missionID;
    }
    if(this.info.missionName){
      this.flyparams.missionName = this.info.missionName
    }
    this.$root.$on('mapOnfinished', async res => {
      L = res.L;
      map = res.map;
      pointIcon = L.divIcon({
        iconSize: [24, 24],
        className: 'xl-point-icon',
        html: '<div></div>'
      });
      anchorIcon = L.divIcon({
        iconSize: [24, 24],
        className: 'anchor-icon',
        html: '<div></div>'
      });
      delIcon = L.divIcon({
        iconSize: [0, 0],
        className: 'xl-del-icon',
        html: '<div class="del-icon"><div class="del-black"></div><div class="del-white"></div></div>'
      });
      startIcon = L.divIcon({
        iconSize: [24, 24],
        className: 'start-icon',
        html: '<div>起</div>'
      });
      endIcon = L.divIcon({
        iconSize: [24, 24],
        className: 'end-icon',
        html: '<div>终</div>'
      });
      moveIcon = L.divIcon({
        className: 'move-tip',
        html: '单击放置航点'
      });
      hangarIcon = L.divIcon({
        className: 'hangar-icon',
        iconSize: [48, 48],
        html: '<div></div>'
      });
      map.createPane('start-end-layers');
      map.getPane('start-end-layers').style.zIndex = 601;
      await this.drawSite();
      this.initMap();
      this.initData();
    });
  },
  beforeDestroy() {
    if (moveTip) {
      map.removeLayer(moveTip);
      moveTip = null;
    }
    this.areaDel();
    if (hangarMarker) {
      map.removeLayer(hangarMarker);
      hangarMarker = null;
    }
    this.lineDel();
    this.$root.$off('mapOnfinished');
    L = null;
    map = null;
    pointIndex = -1;
    addIndex = -1;
  },

  methods: {
    back(msg){
      this.$emit('back',msg)
    },

    // 删除航线
    del(){
       this.$confirm('此操作将删除航线信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { code, reason } = await undockMission(this.missionID);
          if (statusInclude(code)) {
            //this.$message({ type: 'success', message: '删除成功' });
            this.back('删除成功')
          } else {
            this.$message({ type: 'error', message: `删除失败${reason}` });
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    async getMissionInfo(missionID) {
      return await new Promise((resolve, reject) => {
        const res = getMissionDetail(missionID);
        resolve(res);
      });
    },

    initData() {
      if (!this.missionID) {
        return;
      }

      this.getMissionInfo(this.missionID).then(({ data }) => {
        // @ts-ignore
        const { missionName, cameraModel, flightParams: { cruiseSpeed, cruiseAltitude, cruiseGimbalPitch, longitudinalOverlapRate, lateralOverlapRate, THMode, THModeParam, flightBoundary, FRouteRMAngle,  takeOffAltitude } = {} } = data;
        this.flyparams = Object.assign(this.flyparams, {
          missionName,
          cameraModel,
          cruiseSpeed,
          cruiseAltitude,
          takeOffAltitude,
          cruiseGimbalPitch,
          longitudinalOverlapRate,
          lateralOverlapRate,
          THMode,
          THModeParam,
          FRouteRMAngle
        });
       
        flightBoundary.forEach(v => {
          const [lng,lat ]    = coordtransform.wgs84togcj02(v[0], v[1]);
          this.pointDraw(
            {
              lat,
              lng
            },
            false
          );
        });
        if (pointsArr && pointsArr.length > 0) {
          // map.fitBounds(pointsArr);
          map.setView(pointsArr[0]);
        }
      });
    },
    async drawSite() {
      const siteID = this.siteID;
      if (!siteID) {
        return;
      }
      const {code,data,reason} = await getSiteDetail(siteID);
      if(statusInclude(code)){
         const { siteLocation = [], siteMode = 0, siteAltitude = 0 } =data
      this.flyparams.takeOffAltitude = siteAltitude;
      if (siteLocation.length > 0 && siteLocation[0] && siteLocation[1]) {
        const [lng,lat ]   = coordtransform.wgs84togcj02(siteLocation[0], siteLocation[1]);
          this.hangarPoint = [lat, lng];
          hangarMarker = L.marker(this.hangarPoint, {
            icon: hangarIcon
          }).addTo(map);
        map.setView([lat, lng]);
      }
      }else{
         this.$message({ type: 'error', message:reason });
      }
    },
    initMap() {
      this.eventBind();
    },
    eventBind() {
      map
        .on('click', e => {
          this.pointDraw(
            {
              lat: e.latlng.lat,
              lng: e.latlng.lng
            },
            false
          );
        })
        .on('mousemove', e => {
          if (!L) {
            return;
          }
          if (moveTip) {
            moveTip.setLatLng([e.latlng.lat, e.latlng.lng]);
          } else {
            moveTip = L.marker([e.latlng.lat, e.latlng.lng], {
              icon: moveIcon,
              pane: 'tooltipPane'
            }).addTo(map);
          }
        });
    },
    sliderChangeEvent(val, model) {
      // @ts-ignore
      this.flyparams[model] = val;
    },
    delPt(data) {
      map.removeLayer(data.target);
      // eslint-disable-next-line
      pointsArr.some((val, index) => {
        if (val[0] === data.latlng.lat && val[1] === data.latlng.lng) {
          pointsArr.splice(index, 1);
          return true;
        }
      });
      // eslint-disable-next-line
      markerPointList.some((val, index) => {
        // eslint-disable-next-line
        if (val._latlng.lat === data.latlng.lat && val._latlng.lng === data.latlng.lng) {
          markerPointList.splice(index, 1);
          return true;
        }
      });
      $('.move-tip').removeClass('long').text('单击放置航点');
      this.lineDraw();
    },
    pointDraw(data, add) {
      const { lat, lng } = data;
      if (!lat || !lng || this.dragging) {
        return;
      }
      if (add) {
        this.pointsAdd(data);
      } else {
        pointsArr.push([data.lat, data.lng]);
      }
      const markerPoint = L.marker([data.lat, data.lng], {
        icon: pointIcon,
        draggable: true
      })
        .addTo(map)
        .on('click', e => {
          this.delPt(e);
        })
        .on('dragstart', e => {
          this.getCurrentPtIndex(e);
        })
        .on('drag', e => {
          const { latlng } = e;
          this.updateCurrentPoint(latlng);
          this.dragging = true;
        })
        .on('dragend', () => {
          setTimeout(() => {
            this.dragging = false;
          }, 500);
        })
        .on('mouseover', () => {
          $('.move-tip').addClass('long').text('单击删除点，拖拽改变位置');
        })
        .on('mouseout', () => {
          $('.move-tip').removeClass('long').text('单击放置航点');
        });
      if (add) {
        markerPointList.splice(addIndex + 1, 0, markerPoint);
      } else {
        markerPointList.push(markerPoint);
      }
      this.lineDraw();
    },
    getCurrentPtIndex(e) {
      // 获取拖拽点index
      // eslint-disable-next-line
      const latlng = e.target._latlng;
      // eslint-disable-next-line
      pointsArr.some((val, index) => {
        if (latlng.lat === val[0] && latlng.lng === val[1]) {
          pointIndex = index;
        }
      });
    },
    updateCurrentPoint(latlng) {
      pointsArr[pointIndex] = [latlng.lat, latlng.lng];
      this.lineDraw();
    },
    pointsAdd(data) {
      const { lat, lng } = data;
      const addIndexT = anchorPoints.findIndex(x => {
        const [anchorLng, anchorLat] = x;
        if (anchorLat === lat && anchorLng === lng) {
          return true;
        }
        return false;
      });
      pointsArr.splice(addIndexT + 1, 0, [lat, lng]);
    },
    lineDraw() {
      if (!lineLayer) {
        lineLayer = L.polygon(pointsArr, {
          color: 'yellow',
          fillColor: '#00FFE8',
          fillOpacity: 0.12,
          weight: 4
        }).addTo(map);
      } else {
        lineLayer.setLatLngs(pointsArr);
      }
      this.anchorDraw();
      this.areaCalc();
      this.crossLineDraw();
    },
   
    lineDel() {
      if (lineLayer) {
        map.removeLayer(lineLayer);
        lineLayer = null;
      }
    },
    drawHangarLine() {
      if (!this.hangarPoint.length) {
        return;
      }
      if (!hangerLine) {
        hangerLine = L.polygon([this.hangarPoint, takeOff], {
          color: 'yellow',
          fillColor: '#00FFE8',
          fillOpacity: 0.12,
          weight: 4
        }).addTo(map);
      } else {
        hangerLine.setLatLngs([this.hangarPoint, takeOff]);
      }
    },
    delHangarLine() {
      if (hangerLine) {
        map.removeLayer(hangerLine);
        hangerLine = null;
      }
    },
    anchorAddPoint(latlng) {
      this.pointDraw(latlng, true);
    },
    anchorDraw() {
      if (anchorPointList.length > 0) {
        this.anchorDel();
      }
      const len = pointsArr.length;
      if (len < 2) {
        return;
      }
      if (len < 3) {
        anchorPoints.push(midpoint(point([pointsArr[0][1], pointsArr[0][0]]), point([pointsArr[1][1], pointsArr[1][0]])).geometry.coordinates);
      } else {
        for (let i = 0; i < len - 1; i++) {
          anchorPoints.push(midpoint(point([pointsArr[i][1], pointsArr[i][0]]), point([pointsArr[i + 1][1], pointsArr[i + 1][0]])).geometry.coordinates);
        }
        anchorPoints.push(midpoint(point([pointsArr[len - 1][1], pointsArr[len - 1][0]]), point([pointsArr[0][1], pointsArr[0][0]])).geometry.coordinates);
      }
      anchorPoints.forEach(v => {
        anchorPointList.push(
          L.marker([v[0], v[1]], {
            icon: anchorIcon
          })
            .addTo(map)
            .on('click', e => {
              this.anchorAddPoint(e.latlng);
            })
        );
      });
    },
    anchorDel() {
      anchorPointList.forEach(val => {
        map.removeLayer(val);
      });
      anchorPointList = [];
      anchorPoints = [];
    },
    areaCalc() {
      if (pointsArr.length <= 2) {
        this.area = 0;
        return;
      }
      let newpPointsArr = pointsArr.concat([pointsArr[0]]);
      newpPointsArr = newpPointsArr.map(val => [val[1], val[0]]);
      const polygon = turfPolygon([newpPointsArr]);
      let area = turfArea(polygon);
      if (area > 10 * 1000 * 1000) {
        area = area / 1000 / 1000;
        this.areaUnit = 'k㎡';
      } else {
        this.areaUnit = '㎡';
      }
      this.area = Number(area.toFixed(2));
      const centerPoint = center(featureCollection(newpPointsArr.map(x => point(x))));
      if (centerPoint && centerPoint.geometry && centerPoint.geometry.coordinates) {
        this.delBtnSet(centerPoint.geometry.coordinates);
      }
    },
    delBtnSet(po) {
      if (!delBtn) {
        delBtn = L.marker([po[1], po[0]], {
          icon: delIcon,
          draggable: true
        })
          .addTo(map)
          .on('click', () => {
            this.areaDel();
          });
        delBtn
          .bindTooltip('<div>删除航线</div>', {
            direction: 'bottom',
            offset: L.point(0, 15),
            className: 'del-tip'
          })
          .addTo(map)
          .on('mouseover', () => {
            $('.move-tip').hide();
          })
          .on('mouseout', () => {
            $('.move-tip').show();
          });
      } else {
        delBtn.setLatLng([po[1], po[0]]);
      }
    },
    areaDel() {
      markerPointList.forEach(val => {
        map.removeLayer(val);
      });
      markerPointList = [];
      pointsArr = [];
      this.delHangarLine();
      $('.move-tip').show();
      this.lineDraw();
    },
    crossLineDraw() {
      crossPointsList = [];
      this.getCurrentCamera();
      if (pointsArr.length < 3) {
        this.distance = 0;
        this.flyTime = 0;
        this.photoNumber = 0;
        this.resolutionRatio = 0;
        this.delBtnDel();
        this.crossLineDel();
        return;
      }
      crossPointsList.push(...this.crossPointsCalc(this.flyparams.FRouteRMAngle));
      console.log(crossPointsList)
      this.getPhotoNum();
      if (crossPointsList.length > 0) {
        this.getStartEnd();
        this.drawStartEnd(takeOff, land);
        this.drawHangarLine();
        this.distanceCalc();

        if (!crossLine) {
          crossLine = L.polyline(crossPointsList, {
            color: '#00FFE8',
            weight: 4
          }).addTo(map);
        } else {
          crossLine.setLatLngs(crossPointsList);
        }
      } else {
        this.crossLineDel();
      }
    },
    getStartEnd() {
      if (this.switchFlag) {
        takeOff = crossPointsList[crossPointsList.length - 1];
        land = crossPointsList[0];
      } else {
        takeOff = crossPointsList[0];
        land = crossPointsList[crossPointsList.length - 1];
      }
    },
    drawStartEnd(start, end) {
      if (!startPoint) {
        startPoint = L.marker(start, {
          icon: startIcon,
          pane: 'start-end-layers'
        }).addTo(map);
      } else {
        startPoint.setLatLng(start);
      }
      if (!endPoint) {
        endPoint = L.marker(end, {
          icon: endIcon,
          pane: 'start-end-layers'
        }).addTo(map);
      } else {
        endPoint.setLatLng(end);
      }
      this.startEndFlag = true;
    },
    changeStartEnd() {
      this.switchFlag = !this.switchFlag;
      map.removeLayer(startPoint).removeLayer(endPoint);
      this.getStartEnd();
      startPoint = L.marker(takeOff, {
        icon: startIcon,
        pane: 'start-end-layers'
      }).addTo(map);
      endPoint = L.marker(land, {
        icon: endIcon,
        pane: 'start-end-layers'
      }).addTo(map);
      this.delHangarLine();
      this.drawHangarLine();
    },
    distanceCalc() {
      this.totalDistance = 0;
      // const taskSplitIndex = [];
      // let splitIndex = 0;
      // let distanceTemp = 0;

      const pathPoints = this.flightPathPoint;
      pathPoints.forEach((val, index) => {
        if (index > 0) {
          const signleDistance = distance(point([pathPoints[index - 1].lng, pathPoints[index - 1].lat]), point([val.lng, val.lat]));
          // distanceTemp += signleDistance;
          // const time = (distanceTemp * 1000 / this.flyparams.cruiseSpeed + (index - splitIndex) * 3) / 60;
          // if (time > 20) {
          //   splitIndex = index - 1;
          //   taskSplitIndex.push(splitIndex);
          //   distanceTemp = signleDistance;
          // }
          this.totalDistance += signleDistance;
        }
      });
      // this.taskSplitIndex = taskSplitIndex;
      this.distance = Number(this.totalDistance.toFixed(2));
      this.getFlightTime();
    },
    getFlightTime() {
      // 飞行时间
      if (this.totalDistance) {
        this.flyTime = Number((((this.totalDistance * 1000) / this.flyparams.cruiseSpeed + (this.flightPathPoint.length + 2) * 3 + 80 * 2) / 60).toFixed(2));
      }
    },
    getShootTime() {
      this.flyparams.THModeParam = Number((this.flyparams.headSpace / this.flyparams.cruiseSpeed).toFixed(2));
    },
    getCurrentCamera() {
      const currentCamera = this.cameraList.find(x => x.value === this.flyparams.cameraModel);
      const { sizewidth = 0, sizeheight = 0, resolutionwidth = 0, focuslength = 0 } = currentCamera;

      const focusLengthPhysics = this.focusLengthPhysicsCalc(this.flyparams.eq35mm, focuslength, sizewidth, sizeheight);

      const realWidth = (sizewidth / focusLengthPhysics) * (this.flyparams.cruiseAltitude + this.flyparams.takeOffAltitude);
      const realHeight = (sizeheight / focusLengthPhysics) * (this.flyparams.cruiseAltitude + this.flyparams.takeOffAltitude);
      this.realWidth = realHeight;
      this.flyparams.sideSpace = realWidth * (1 - this.flyparams.lateralOverlapRate / 100);

      this.flyparams.headSpace = realHeight * (1 - this.flyparams.longitudinalOverlapRate / 100);
      this.resolutionRatio = +`${(((sizewidth / focusLengthPhysics) * (this.flyparams.cruiseAltitude + this.flyparams.takeOffAltitude) * 100) / resolutionwidth).toFixed(2)}`;
      this.getShootTime();
    },

    focusLengthPhysicsCalc(eq35mm = true, focuslength = 0, sizewidth = 0, sizeheight = 0) {
      return eq35mm ? focuslength / (43.27 / Math.sqrt(sizewidth ** 2 + sizeheight ** 2)) : focuslength;
    },
    crossPointsCalc(FRouteRMAngle = 60) {
      const maxDistance = (pt, pts) => {
        let ds = 0;
        pts.forEach(x => {
          const d = distance(pt, point(x));
          if (d > ds) {
            ds = d;
          }
        });
        return ds;
      };
      const pointSort = (points, end) =>
        points
          .map((p, idx) => ({ distance: distance(end, p), index: idx }))
          .sort((a, b) => a.distance - b.distance)
          .map(p => points[p.index]);
      const isInPoints = (points, pt) => {
        let f = false;
        points.forEach(p => {
          if (p[0] === pt[0] && p[1] === pt[1]) {
            f = true;
          }
        });
        return f;
      };
      const intersectLine = (lines, line) => {
        let ps = [];

        lines.forEach(l => {
          const c = lineIntersect(l, line);
          if (c.features.length === 0) {
            return;
          }

          ps = ps.concat(c.features);
        });

        return ps;
      };
      // eslint-disable-next-line no-unused-vars
      const moveAlongLine = (start, end, points) => {
        let sIndex = -1;
        let eIndex = -1;
        const sPoint = point(start);
        const ePoint = point(end);
        for (let i = 0, l = points.length; i <= l - 1; i += 1) {
          const s = point(points[i]);
          const e = i === l - 1 ? point(points[0]) : point(points[i + 1]);

          const as = [bearing(s, sPoint).toFixed(1), bearing(s, e).toFixed(1)];
          if (as[0] === as[1]) {
            sIndex = i;
          }

          const ae = [bearing(s, ePoint).toFixed(1), bearing(s, e).toFixed(1)];
          if (ae[0] === ae[1]) {
            eIndex = i;
          }
        }

        const between = [];
        if (sIndex === eIndex) {
          return between;
        }
        if (sIndex > eIndex) {
          for (let i = sIndex; i > eIndex; i -= 1) {
            between.push(points[i].slice());
          }
        } else {
          for (let i = sIndex + 1; i < eIndex + 1; i += 1) {
            between.push(points[i].slice());
          }
        }

        return between;
      };

      const points = pointsArr.map(x => [x[1], x[0]]);
      const sideSpace = this.flyparams.sideSpace / 1000; // 转换单位 km

      const centerPoint = center(featureCollection(points.map(x => point(x))));
      const maxDis = maxDistance(centerPoint, points);
      const circleRadius = maxDis * 1.5;
      const squareCatercornerHalf = circleRadius * 1.41421356237;
      const firstPoint = rhumbDestination(centerPoint, squareCatercornerHalf, FRouteRMAngle - 145);
      const helpLineNum = 1 + Math.ceil((circleRadius * 2) / sideSpace);

      const helpLines = [];
      const helpPoints = [];
      for (let i = 0; i < helpLineNum; i += 1) {
        const s = rhumbDestination(firstPoint, sideSpace * i, FRouteRMAngle + 90);
        const e = rhumbDestination(s, circleRadius * 2, FRouteRMAngle);
        const l = lineString([s.geometry.coordinates, e.geometry.coordinates]);

        helpLines.push(l);
        helpPoints.push(s);
      }

      const shape = [];
      for (let i = 0, l = points.length; i <= l - 1; i += 1) {
        shape.push(lineString([points[i], i === l - 1 ? points[0] : points[i + 1]]));
      }

      const crossPoints = [];
      let lastPoint = firstPoint;
      if (this.flyparams.waymode === 'scan') {
        helpLines.forEach((l, i) => {
          const c = intersectLine(shape, l);
          const len = c.length;
          if (len === 0 || len === 1) {
            return;
          }

          const d = pointSort(c, helpPoints[i]);

          if (len >= 2) {
            const p1 = d[0];
            const p2 = d[len - 1];

            if (distance(lastPoint, p1) > distance(lastPoint, p2)) {
              d.reverse().forEach(t => {
                crossPoints.push(t.geometry.coordinates.slice().reverse());
                lastPoint = t;
              });
            } else {
              d.forEach(t => {
                crossPoints.push(t.geometry.coordinates.slice().reverse());
                lastPoint = t;
              });
            }
          }
        });
      } else {
        // inner
        let lastNumOfPoints = 0;

        const laterPointsGroup = [];
        let curLaterPoints = [];

        helpLines.forEach((l, i) => {
          const c = intersectLine(shape, l);
          let len = c.length;
          if (len === 0 || len === 1) {
            return;
          }

          if (len === 2) {
            if (c.some(y => !isInPoints(points, y.geometry.coordinates)) === false) {
              return;
            }

            const p1 = c[0];
            const p2 = c[1];
            if (distance(lastPoint, p1) > distance(lastPoint, p2)) {
              c.reverse().forEach(t => {
                crossPoints.push(t.geometry.coordinates.slice().reverse());
                lastPoint = t;
              });
            } else {
              c.forEach(t => {
                crossPoints.push(t.geometry.coordinates.slice().reverse());
                lastPoint = t;
              });
            }

            lastNumOfPoints = len;

            return;
          }

          if (len > 2) {
            let d = c.filter(y => !isInPoints(points, y.geometry.coordinates));
            len = d.length;
            if (len % 2 !== 0) {
              return;
            }

            if (len !== lastNumOfPoints) {
              curLaterPoints.forEach(x => {
                laterPointsGroup.push(x);
              });
              curLaterPoints = [];
            }

            d = pointSort(d, helpPoints[i]);

            const p1 = d[0];
            const p2 = d[1];
            if (distance(lastPoint, p1) > distance(lastPoint, p2)) {
              crossPoints.push(p2.geometry.coordinates.slice().reverse());
              crossPoints.push(p1.geometry.coordinates.slice().reverse());
              lastPoint = p1;
            } else {
              crossPoints.push(p1.geometry.coordinates.slice().reverse());
              crossPoints.push(p2.geometry.coordinates.slice().reverse());
              lastPoint = p2;
            }

            if (len > 2) {
              // eslint-disable-next-line no-shadow
              for (let i = 2; i < len; i += 2) {
                const q1 = d[i];
                const q2 = d[i + 1];

                const idx = Math.floor((i - 2) / 2);
                if (!curLaterPoints[idx]) {
                  curLaterPoints[idx] = [];
                }

                curLaterPoints[idx].push(q1);
                curLaterPoints[idx].push(q2);
              }
            }

            lastNumOfPoints = len;
          }
        });

        if (curLaterPoints.length) {
          curLaterPoints.forEach(x => {
            laterPointsGroup.push(x);
          });
          curLaterPoints = [];
        }

        let lastSegEnd = crossPoints[crossPoints.length - 1].slice().reverse();
        laterPointsGroup.forEach(px => {
          // eslint-disable-next-line prefer-destructuring
          lastPoint = px[0];

          const ph = moveAlongLine(lastSegEnd, lastPoint.geometry.coordinates.slice(), points); // [lng, lat]
          ph.forEach(x => {
            crossPoints.push(x.slice().reverse());
          });

          for (let i = 0, l = px.length; i < l; i += 2) {
            const p1 = px[i];
            const p2 = px[i + 1];
            if (distance(lastPoint, p1) > distance(lastPoint, p2)) {
              crossPoints.push(p2.geometry.coordinates.slice().reverse());
              crossPoints.push(p1.geometry.coordinates.slice().reverse());
              lastPoint = p1;

              lastSegEnd = p1.geometry.coordinates.slice();
            } else {
              crossPoints.push(p1.geometry.coordinates.slice().reverse());
              crossPoints.push(p2.geometry.coordinates.slice().reverse());
              lastPoint = p2;

              lastSegEnd = p2.geometry.coordinates.slice();
            }
          }
        });
      }
      return crossPoints;
    },
    delBtnDel() {
      if (delBtn) {
        map.removeLayer(delBtn);
        delBtn = null;
      }
    },
    crossLineDel() {
      crossPointsList = [];
      this.startEndFlag = false;
      if (startPoint) {
        map.removeLayer(startPoint);
        startPoint = null;
        takeOff = null;
      }
      if (endPoint) {
        map.removeLayer(endPoint);
        endPoint = null;
        land = null;
      }
      if (crossLine) {
        map.removeLayer(crossLine);
        crossLine = null;
      }
    },
    getPhotoNum() {
      const flightPathPoint = [];
      if (crossPointsList.length) {
        for (let i = 0; i < Math.floor(crossPointsList.length / 2); i += 1) {
          const start = crossPointsList[2 * i];
          const end = crossPointsList[2 * i + 1];
          const l = this.getPointLength(start.slice().reverse(), end.slice().reverse());

          const angle = this.getHeadingFromCoordinates(start, end);
          const firstPoint = { lng: start[1], lat: start[0] };
          flightPathPoint.push(firstPoint);
          let distanceX = 0;
          let current = firstPoint;
          while (distanceX <= l) {
            const pointX = this.getLonAndLat(current.lng, current.lat, angle, this.flyparams.headSpace);
            current = pointX;
            flightPathPoint.push(pointX);
            distanceX += this.flyparams.headSpace;
          }
        }
      }

      this.photoNumber = flightPathPoint.length ?? 0;
      this.flightPathPoint = flightPathPoint;
    },
    getPointLength(one, two) {
      return distance(point(one), point(two)) * 1000;
    },
    submitTask() {
      if (!crossPointsList || crossPointsList.length <= 0) {
        this.$message.warning('请创建有效航线');
        return;
      }
      if (!pointsArr || pointsArr.length <= 2) {
        this.$message.warning('多边形测绘至少需要3个航点');
        return;
      }
      if (this.flyTime > 20) {
        this.crossLineDel();
        this.$message.warning('飞行时间超出20分钟最大限制，请缩小航线范围');
        return;
      }
      if (!this.flyparams.missionName) {
        this.$message.warning('名称不能为空');
        return;
      }
      this.saveTask();
    },
    async saveTask() {
      const flightBoundary = [];
      pointsArr.forEach(val => {
        flightBoundary.push([Number(val[1].toFixed(6)), Number(val[0].toFixed(6))]);
      });

      const { missionName, cameraModel, cruiseSpeed, cruiseAltitude, cruiseGimbalPitch, longitudinalOverlapRate, lateralOverlapRate, THMode, THModeParam, FRouteRMAngle, takeOffAltitude } = this.flyparams;

      const childrenList = [];
      childrenList.push({
        index: 0,
        flightPath: this.flightPathPoint.map((n, i) => ({
          index: i,
          altitude: cruiseAltitude,
          heading: 9999,
          gimbalPitch: cruiseGimbalPitch,
          latitude: n.lat,
          longitude: n.lng
        }))
      });

      const params = {
        missionName,
        missionType: 2,
        ASName: '',
        ASType: 3,
        cameraModel,
        siteID: this.siteID,
        PID: '',
        CPID: '',
        isRevise: 0,
        flightParams: {
          GCSType: 2,
          cruiseSpeed,
          cruiseAltitude,
           takeOffAltitude,
          cruiseGimbalPitch,
          longitudinalOverlapRate,
          lateralOverlapRate,
          THMode,
          THModeParam,
          flightBoundary,
          THPTotal: this.photoNumber,
          FRouteRMAngle,
          childrenList
        }
      };
      let code1 = -1;
      let message = '';
      if (this.missionID) {
        params.missionID = this.missionID;
        const { code, reason } = await updateMission(params);
        code1 = code;
        message = reason;
      } else {
        const { code, reason } = await insertMission(params);
        code1 = code;
        message = reason;
      }
      if (statusInclude(code1)) {
       // this.$message({ type: 'success', message:'编辑成功！' });
        this.back('编辑成功！')
      } else {
        this.$message({ type: 'error', message });
      }
    },
    getHeadingFromCoordinates(start, end) {
      const pStart = map.latLngToContainerPoint(start);
      const pEnd = map.latLngToContainerPoint(end);
      const diffX = pStart.x - pEnd.x;
      const diffY = -(pStart.y - pEnd.y);
      let angle = (360 * Math.atan2(diffY, diffX)) / (2 * Math.PI) + 90;
      angle = angle < 0 ? -angle : 360 - angle;
      return angle;
    },
    getLonAndLat(lng, lat, brng, dist) {
      const a = 6378137;
      const b = 6356752.3142;
      const f = 1 / 298.257223563;

      const lon1 = lng * 1;
      const lat1 = lat * 1;
      const s = dist;
      const alpha1 = brng * (Math.PI / 180);
      const sinAlpha1 = Math.sin(alpha1);
      const cosAlpha1 = Math.cos(alpha1);

      const tanU1 = (1 - f) * Math.tan(lat1 * (Math.PI / 180));
      const cosU1 = 1 / Math.sqrt(1 + tanU1 * tanU1);
      const sinU1 = tanU1 * cosU1;
      const sigma1 = Math.atan2(tanU1, cosAlpha1);
      const sinAlpha = cosU1 * sinAlpha1;
      const cosSqAlpha = 1 - sinAlpha * sinAlpha;
      const uSq = (cosSqAlpha * (a * a - b * b)) / (b * b);
      const A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
      const B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));

      let sigma = s / (b * A);
      let sigmaP = 2 * Math.PI;
      let cos2SigmaM = 0;
      let sinSigma = 0;
      let cosSigma = 0;
      while (Math.abs(sigma - sigmaP) > 1e-12) {
        cos2SigmaM = Math.cos(2 * sigma1 + sigma);
        sinSigma = Math.sin(sigma);
        cosSigma = Math.cos(sigma);
        const deltaSigma = B * sinSigma * (cos2SigmaM + (B / 4) * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) - (B / 6) * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
        sigmaP = sigma;
        sigma = s / (b * A) + deltaSigma;
      }

      const tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
      const lat2 = Math.atan2(sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1, (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp));
      const lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1);
      const C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
      const L = lambda - (1 - C) * f * sinAlpha * (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));

      const revAz = Math.atan2(sinAlpha, -tmp); // final bearing

      const lngLatObj = { lng: lon1 + L * (180 / Math.PI), lat: lat2 * (180 / Math.PI) };
      return lngLatObj;
    }
  }
};
</script>

<style lang="scss">
.black-theme {
  &.ant-select-dropdown {
    display: block;
    background: rgba(30, 30, 30, 0.85);
    border: 1px solid #47515c;
    border-radius: 3px;
    .ant-select-dropdown-menu {
      &::-webkit-scrollbar {
        width: 3px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 3px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .ant-select-dropdown-menu-item {
      color: #a1a1a1;
      font-size: 14px;
      display: flex;
      align-items: center;
    }
    .ant-select-dropdown-menu-item-disabled {
      color: #626262;
    }
    .ant-select-dropdown-menu-item-selected {
      background-color: transparent;
      color: #36c6fb;
      position: relative;
      &::after {
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 6px;
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -3px;
        background-color: #36c6fb;
        box-shadow: 0 0 10px #36c6fb;
      }
    }
    .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: rgba(0, 0, 0, 0.4);
    }
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: transparent;
    }
    .ant-empty-normal {
      color: rgba(255, 255, 255, 0.25);
      margin: 8px auto;
    }
  }
}
.area {
  .back{
    position: absolute;
    right: 10px;
    top: 10px;
    width: auto;
    padding: 0 10px;
    z-index: 10000;
    height: 32px;
    border-radius: 3px;
    border: 0;
    color: black;
    background: #36c6fb;
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
  }
  #uav-icon {
    width: 60px;
    position: absolute;
    z-index: 401;
    left: 350px;
    top: 38px;
  }
  .move-tip {
    width: 90px !important;
    margin-left: 10px !important;
    margin-top: 20px !important;
    height: 24px !important;
    color: #000;
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(0, 0, 0, 0.85);
    border-radius: 2px;
    &.long {
      width: 160px !important;
    }
  }
  // .select-item {
  //   .number-c {
  //     width: 150px;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     .ant-input-number {
  //       width: 70px;
  //       background: rgba(255,255,255,0.16);
  //       border: 1px solid transparent !important;
  //       color: #fff;
  //     }
  //   }
  // }
  .start-icon {
    width: 24px !important;
    height: 24px !important;
    background-color: #049c28;
    border: solid 2px #fff;
    border-radius: 50%;
    div {
      width: 100% !important;
      height: 100% !important;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      color: #fff;
    }
  }
  .end-icon {
    width: 24px !important;
    height: 24px !important;
    background-color: #ff6701;
    border: solid 2px #fff;
    border-radius: 50%;
    div {
      width: 100% !important;
      height: 100% !important;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      color: #fff;
    }
  }
  .xl-point-icon {
    width: 24px !important;
    height: 24px !important;
    background-color: rgba(3, 122, 255, 1);
    border: solid 2px #fff;
    border-radius: 50%;
    div {
      width: 100% !important;
      height: 100% !important;
      font-size: 12px;
      text-align: center;
      line-height: 20px;
      color: #fff;
    }
  }
  .anchor-icon {
    width: 24px !important;
    height: 24px !important;
    background-color: #fff;
    border: solid 2px #fff;
    border-radius: 50%;
    div {
      width: 100% !important;
      height: 100% !important;
      background: url(~@/assets/images/mission/plus_black.svg) no-repeat;
      background-size: contain;
    }
  }
  .hangar-icon {
    width: 96px !important;
    height: 70px !important;
    background: url(~@/assets/images/mission/site.png);
    background-size: contain;
    div {
      width: 100% !important;
      height: 100% !important;
      font-size: 16px;
      text-align: center;
      line-height: 46px;
      color: #fff;
    }
  }
  .del-tip {
    background-color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    color: #fff;
    border-color: rgba(0, 0, 0, 0.85);
    &::before {
      border-bottom-color: rgba(0, 0, 0, 0.85);
    }
  }
  .xl-del-icon {
    width: 0;
    height: 0;
    position: relative;
    .del-icon {
      position: absolute;
      top: -12px;
      left: 20px;
      width: 25px !important;
      height: 24px !important;
      background-color: #fff;
      border: solid 1px rgba(0, 0, 0, 0.85);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      .del-black {
        display: block;
        width: 100%;
        height: 100%;
        background: url(~@/assets/images/mission/task/del_black.svg) no-repeat;
        background-size: contain;
      }
      .del-white {
        display: none;
      }
      &:hover {
        background-color: rgba(250, 84, 28, 1);
        border: solid 1px rgba(250, 84, 28, 1);
        .del-black {
          display: none;
        }
        .del-white {
          display: block;
          width: 100%;
          height: 100%;
          background: url(~@/assets/images/mission/task/del_white.svg) no-repeat;
          background-size: contain;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.area {
  height: calc(100% - 32px);
  display: flex;
  position: relative;
  .modal-left {
    width: 300px;
    position: absolute;
    z-index: 401;
    left: 20px;
    top: 20px;
    background: var(--main-layout-container-background);
    border: 1px solid #6a6a6a;
    padding: 20px 0;
    transition: left 0.5s;
    &.modal-shrink {
      left: -300px;
    }
    .shrink {
      width: 20px;
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      right: -20px;
      cursor: pointer;
    }
    .common-header {
      display: flex;
      align-items: center;
      padding: 0 20px;
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #36c6fb;
        margin-right: 16px;
      }
      .title {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .x-icon-btn {
      margin: 0 20px;
      color: #1e1e1e;
      font-weight: 500;
      padding: 6px 16px;
      background: #36c6fb;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
    .task-mainer {
      height: calc(100vh - 250px);
      overflow-y: auto;
      padding: 0 20px 20px;
      .task-name {
        /deep/.ant-input {
          color: #ffffff;
          background: rgba(106, 106, 106, 0.2);
          border: 0;
          padding: 0 22px;
        }
        padding-top: 20px;
        img {
          width: 14px;
        }
      }
      .task-address {
        display: flex;
        align-items: center;
        margin: 12px 0 20px;
        span {
          font-size: 12px;
          color: #a1a1a1;
          margin-left: 10px;
        }
        img {
          width: 12px;
        }
      }
      .task-icon-module {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        .module {
          margin-bottom: 20px;
          img {
            width: 48px;
          }
          .text {
            font-weight: bold;
            font-size: 14px;
            color: #ffffff;
            margin-top: 10px;
          }
          .unit {
            font-size: 12px;
            color: #a1a1a1;
          }
        }
      }
      .task-line-module {
        .filter-box {
          margin: 14px 0 20px;
          /deep/.ant-select-selection {
            color: #fff;
            background: var(--main-layout-container-background);
            border: 1px solid #47515c;
            border-radius: 3px;
            // font-weight: 500;
            box-shadow: none !important;
          }
          /deep/.ant-select-arrow {
            color: #a1a1a1;
          }
        }
        .shoot-inner {
          font-size: 14px;
          .label {
            color: #a1a1a1;
          }
          .text {
            color: #ffffff;
          }
        }
      }
      .task-line-module {
        .label-module {
          display: flex;
          align-items: center;
        }
        img {
          width: 14px;
        }
        .label {
          color: #a1a1a1;
          font-size: 14px;
          margin: 0 10px;
        }
      }
      .take-off {
        margin: 14px 0 20px;
        display: flex;
        .label {
          font-size: 14px;
          color: rgba(205, 205, 205, 1);
        }
        /deep/.ant-switch {
          height: 22px;
          background-color: #47515c;
          &::after {
            width: 18px;
            height: 18px;
          }
        }
        /deep/.ant-switch-checked {
          background-color: #36c6fb;
        }
      }
    }
  }
  .map-custom {
    width: 100%;
    height: 100%;
  }
  .shot-loading {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      padding-left: 10px;
      color: #2196f3;
      font-size: 14px;
    }
  }
}
</style>
