<template>
  <div class="slider-bar-wrap skysys-slider-wrap">
    <div class="title-top-bar">
      <div class="title">{{ title }}</div>
      <div class="value-area">
        <el-input-number v-model="v" size="small" :min="min" :max="max" @change="setValue(v)"></el-input-number>
      </div>
    </div>
    <div class="slider-bar">
      <el-button size="mini" icon="el-icon-minus" @click="reduce()"></el-button>
      <input type="range" v-model="v" :min="min" :max="max" @change="setValue(v)" />
      <el-button size="mini" icon="el-icon-plus" @click="increase()"></el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['uKey', 'title', 'min', 'max', 'value', 'unit'],
  name: 'SliderBar',
  data() {
    return {
      v: this.value,
    };
  },
  methods: {
    reduce() {
      if (this.v > this.min) {
        this.v--;
        this.setValue(this.v);
      }
    },
    increase() {
      if (this.v < this.max) {
        this.v++;
        this.setValue(this.v);
      }
    },
    setValue(v) {
      v = parseInt(v);
      this.$emit('setValue', { v, uKey: this.uKey });
    },
  },
};
</script>

<style lang="scss" scoped>
.slider-bar-wrap {
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  .title-top-bar {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title {
      color: #fff;
    }
    .value-area {
      span {
        display: inline-block;
      }
      .value {
        width: 44px;
        padding: 2px 12px;
        text-align: center;
        background: #eee;
        font-size: 12px;
        color: #313131;
        border-radius: 4px;
      }
      .unit {
        width: 30px;
        background: #eee;
        border-radius: 4px;
        margin: 0 0 0 4px;
        text-align: center;
      }
    }
  }
  .slider-bar {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .el-button {
      font-size: 12px;
      padding: 0 4px;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
