<template>
  <div class="msg-electronic-screens-wrapper">
    <ul class="msg-list">
      <li class="msg" v-for="(msg, index) in msgGroup" :key="index">
        <el-tooltip :content="msg.content" placement="bottom" effect="light">
          <i :class="msg.icon">{{ msg.content }}</i>
        </el-tooltip>
        <span>{{ msg.value }}{{ msg.unit }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MsgElectronic',
  props: ['msgGroup', 'totalDis'],
};
</script>

<style lang="scss" scoped>
.msg-electronic-screens-wrapper {
  width: 280px;
  height: 80px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid var(--main-border-color);
  .msg-list {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--color-primary);
    .msg {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      .el-tooltip {
        font-size: 14px;
      }
    }
  }
}
</style>
