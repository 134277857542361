<template>
  <div v-if="!scanMode" class="mission-plan-wrap">
    <div class="mission-list-components">
      <lookup-mission @retrieve="retrieve" @stId="searchBySID"></lookup-mission>
      <mission-group ref="missionGroup" :query="query" :stId="stId" @missionDetail="missionDetailHandler" @editMission="editMission"
       @copyMission="copyMission" 
        @exportMission="exportMission" 
       @childEmit="getChildEmit" 
       
       @clearMap="clearMap"></mission-group>
      <el-button type="primary" size="small" style="width: 100%; margin: 10px 0 0 0" @click="startDesign">新建任务</el-button>
    </div>
    <div class="content-map-wrapper">
      <div class="reference" v-if="isEdit && site && site.siteSFMode == 2">
        <ModeChoose @changeSite="changeEndSite" :siteList="siteList" />
      </div>
      <cesium-map v-if="is3DMode" ref="cesiumMap" :pts="missionInfo.flightParams.flightPath" :site="site" :endSite="endSite" @rendered="renderedCesium" @twoD="is3DMode = false" />
      <ol-map :site="site" v-show="!is3DMode" ref="map" @postrender="mapRendered" @changePostion="changePostionHandler" @errorMsg="errorMsgHandler" @selected="selectedHandler" @distanceChange="distanceChange" @threeD="is3DMode = true"></ol-map>
    </div>

    <!-- 路点巡航 -->
    <div :class="['right-param-wrapper', isOpen ? 'open' : '']">
      <div v-if="isOpen" v-show="!isMotion">
        <top-bar :missionName="missionInfo.missionName" @close="closeHandler" @emitMissionName="emitMissionName"></top-bar>
        <param-function @sendInstruction="paramFun"></param-function>
        <electronic-screens :msgGroup="msgGroup"></electronic-screens>
        <div class="switch-button-wrapper">
          <el-button class="line switch-mission-param" size="mini" :type="isActive ? 'danger' : 'primary'" @click="isActive = true">航线设置</el-button>
          <el-button class="point switch-mission-param" size="mini" :type="!isActive ? 'danger' : 'primary'" @click="isActive = false">航点设置</el-button>
        </div>
        <div class="air-route-param-wrapper skysys" v-show="isActive">
          <slider-bar u-key="cruiseAltitude" title="航线高度" :min="0" :max="500" v-model="missionInfo.flightParams.cruiseAltitude" unit="m" @setValue="setLineParam"></slider-bar>
          <slider-bar u-key="cruiseSpeed" title="航线速度" :min="1" :max="15" :value="missionInfo.flightParams.cruiseSpeed" unit="m/s" @setValue="setLineParam"></slider-bar>
          <slider-bar u-key="cruiseGimbalPitch" title="航线云台角" :min="-90" :max="0" :value="missionInfo.flightParams.cruiseGimbalPitch" unit="°" @setValue="setLineParam"></slider-bar>
          <el-form style="padding: 10px" label-position="left">
            <el-form-item label="航点拍照">
              <el-switch v-model="missionInfo.flightParams.isGPhoto"></el-switch>
            </el-form-item>
          </el-form>
        </div>
        <div class="waypoints-param-wrapper" v-show="!isActive">
          <el-carousel indicator-position="none" arrow="always" ref="carousel" :initial-index="currentIndex" :autoplay="false" @change="getIndex">
            <el-carousel-item v-for="(marker, index) in missionInfo.flightParams.flightPath" :key="`'carousel_'+${index}`">
              <h3 class="waypoint-index">航点 {{ index + 1 }}</h3>
              <div class="coordinates-ctrl-wrapper">
                <el-form size="mini" label-position="left" label-width="40px">
                  <el-form-item label="经度">
                    <el-input v-model.number.lazy="marker.longitude" ref="longitude" @change="modifyCoordinates(marker)"></el-input>
                  </el-form-item>
                  <el-form-item label="纬度">
                    <el-input v-model.number.lazy="marker.latitude" ref="latitude" @change="modifyCoordinates(marker)"></el-input>
                  </el-form-item>
                </el-form>
                <div class="coordinates-wrapper">
                  <Disk :marker="marker" @setValue="trimmingLngLat" />
                </div>
              </div>

              <el-form size="mini" label-position="left" label-width="90px" style="margin-top: 15px">
                <el-form-item label="机身偏航角">
                  <el-select @change="currentHeadingHandler" v-model="currentPtHeading">
                    <el-option :value="1" label="沿航线方向">沿航线方向</el-option>
                    <el-option :value="0" label="自定义">自定义</el-option>
                  </el-select>
                </el-form-item>
              </el-form>

              <slider-bar v-if="marker.heading > -9999" uKey="flightPath.heading" title="机身偏航角" :min="-180" :max="180" :value="marker.heading" unit="°" @setValue="setMarkerParam"> </slider-bar>
              <slider-bar uKey="flightPath.speed" title="航点速度" :min="1" :max="15" :value="marker.speed" unit="m/s" @setValue="setMarkerParam"></slider-bar>
              <slider-bar uKey="flightPath.gimbalPitch" title="云台俯仰角" :min="-90" :max="0" :value="marker.gimbalPitch" unit="°" @setValue="setMarkerParam"></slider-bar>
              <slider-bar uKey="flightPath.altitude" title="航点高度" :min="-200" :max="500" :value="marker.altitude" unit="m" @setValue="setMarkerParam"></slider-bar>
              <!-- <el-row class="photo-btn">
                <span>航点拍照</span>
                <el-switch v-model="marker.isPhoto"></el-switch>
              </el-row> -->
              <div style="padding: 0 10px">
                编辑动作：
                <el-button type="success" size="mini" @click="getCurrentMarker(marker)">编辑动作</el-button>
                （最多8个）
              </div>
              <div class="action-list-wrap">
                <ul class="action-list">
                  <ActionDraggable v-model="marker.actions" @update="isDragEnd" :disabled="true" v-bind="{ animation: 300 }">
                    <transition-group>
                      <li class="action-item" v-for="(action, idx) in marker.actions" :key="'_action' + idx + '_' + index">
                        <action-item @setEnable="setEnable" :action="action" :type="action.type" :value="action.actionParams" :name="action.name" :min="action.min" :max="action.max" :unit="action.unit" :index="idx" :isEdit="false" @deleteAction="deleteAction"></action-item>
                      </li>
                    </transition-group>
                  </ActionDraggable>
                </ul>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="motion-area-wrapper" v-if="isMotion">
        <el-page-header @back="isMotion = false" content="航点动作设置"></el-page-header>
        <action-menu :marker="currentMarker"></action-menu>
        <el-divider content-position="left">航点动作</el-divider>
        <div class="action-list-wrap">
          <ul class="action-list">
            <ActionDraggable v-model="currentMarker.actions" @update="isDragEnd" :disabled="isDisabled" v-bind="{ animation: 300 }">
              <transition-group>
                <li class="action-item" v-for="(action, index2) in currentMarker.actions" :key="'currentMarker_action_' + index2">
                  <action-item @setEnable="setEnable" :action="action" :type="action.type" :value="action.actionParams" :name="action.name" :min="action.min" :max="action.max" :unit="action.unit" :index="index2" :isEdit="true" @deleteAction="deleteAction(index2)" @setValue="setActionValue"></action-item>
                </li>
              </transition-group>
            </ActionDraggable>
          </ul>
        </div>
      </div>
    </div>
    <crm-mission @setValue="setNewMissionType" v-show="isMask" @enableMask="isMask = false"></crm-mission>
    <el-dialog :close-on-click-modal="false" :title="isUpDataByKML ? '导入任务' : '新建任务'" :visible.sync="dialogVisible" width="30%" :key="'mission_tag_1'">
      <mission-form ref="missionForm" :missionNameGroup="missionNameGroup" :uId="uId" :CPList="CPList" :editMissionMode="editMissionMode" :is-upload-file="isUpDataByKML" @puttingMissionInfo="puttingMissionInfo" @siteData="siteListHandler" @readFile="readFile" @noFile="noFileHandler"> </mission-form>
    </el-dialog>
     <el-dialog :close-on-click-modal="false" title="选择导出文件的类型" :visible.sync="dialogDownloadVisible" width="30%" :key="'mission_tag_2'">
      <el-form class="mission-form-wrapper"  label-width="100px">
        <el-form-item label="文件类型">
          <el-select v-model="fileDownloadType">
            <el-option label="KML" :value="1"></el-option>
            <el-option label="JSON" :value="2"></el-option>
             <!-- <el-option label="KMZ" :value="3"></el-option> -->
          </el-select>
        </el-form-item>
        <el-form-item>
      <el-button @click="dialogDownloadVisible=false">取消</el-button>
      <el-button type="primary" @click="toDownLoad">确定</el-button>
    </el-form-item>
      </el-form>
    </el-dialog>
    <MissionDownload ref="downFile"/>
  </div>
  <scan v-else :info="missionInfo" @back="scanCancel"></scan>

  
</template>

<script>
import Scan from './scan'; 
import LookupMission from './children/loogupMission'; 
import MissionGroup from './children/missionGroup';
import OlMap from '@/components/maps/OlMap.vue';
import CesiumMap from '@/components/maps/CesiumMap';
import MissionDownload from './components/MissionDownload';
import MissionForm from './children/missionForm';
import TopBar from './children/topBar';
import ModeChoose from './children/modeChoose';
import ParamFunction from './children/paramfunctions';
import ElectronicScreens from './children/electronicScreens';
import SliderBar from './children/sliderBar';
import InputPosition from './children/inputPosition';
import Disk from './children/disk';
import ActionDraggable from 'vuedraggable';
import ActionMenu from './children/actionMenu';
import ActionItem from './children/actionItem';
import CrmMission from './children/pageCRMAddMission.vue';
import Mixin from './mixin.js';
import { getSiteDetail, getSiteGroup } from '@/api/site';
import { insertMission, updateMission } from '@/api/mission';
import { getUserInformation, getUserCorporationsInformation } from '@/utils/auth.js';
import statusInclude from '@/utils/statusCode';
import { CLICKMODE } from '../../components/maps/map.config';
import { getUserCorporationList } from '@/api/role';
export default {
  name: 'Mission',
  mixins: [Mixin],
  components: {
    MissionDownload,
    Scan,
    LookupMission,
    MissionGroup,
    OlMap,
    CesiumMap,
    MissionForm,
    TopBar,
    ModeChoose,
    ParamFunction,
    ElectronicScreens,
    SliderBar,
    InputPosition,
    Disk,
    ActionDraggable,
    ActionMenu,
    ActionItem,
    CrmMission,
  },
  data() {
    return {
      scanMode:false,
      currentPtHeading: 1,
      mapModeImg: {
        two: require('@/assets/images/map/2d.png'),
        three: require('@/assets/images/map/3d.png'),
      },
      cesiumInstance: null,
      is3DMode: false,
      STOPSAVEMSG: false, // 临时禁止导入保存
      CPList: [], // 企业列表
      CPID: null,
      CPMAP: new Map(),
      msg: 'mission',
      uId: '',
      hivePos: null,
      endHivePos: null, // 网联模式，降落站点
      projectID: '',
      siteID: '',
      compensate: 0.000005, //微调补偿
      verticalCover: 60,
      horizontalCover: 40,
      currentIndex: -1,
      query: '',
      stId: '',
      site: null,
      endSite: null,
      openLayerMap: null,
      dialogVisible: false,
      dialogDownloadVisible:false,
      fileDownloadType:1,
      isActive: false,
      isDisabled: false,
      editMissionMode: false,
      isOpen: false,
      isMotion: false,
      isEdit: false,
      isMask: false,
      isUpDataByKML: false,
      createdModel: 1,
      basemapList: ['vec', 'img'],
      projectList: [],
      siteList: [],
      flightPolygon: [],
      mapList: [],
      indexs: [],
      missionNameGroup: [],
      msgGroup: [
        { content: '飞行长度', value: 0, unit: 'm' },
        { content: '飞行时间', value: 0, unit: 's' },
        { content: '航点数', value: 0, unit: null },
      ],
      siteTagDetails: {},
      missionDetail: {},
      routeInformation: {
        distance: 0,
        count: 0,
        time: 0,
      },
      missionInfo: {
        flightParams: {
          cruiseAltitude: 120,
        },
      },
      currentMarker: null,
    };
  },

  watch: {
    dialogVisible(t) {
      if (!t) {
        this.$refs.missionForm.clearCascaderValue();
      }
    },
    isOpen: {
      handler(n) {
       // this.map.updateSize();
        if (!n) {
          this.map.selectOnePoint(null);
        }
      },
    },

    isEdit: {
      handler(n) {
        if (n) {
          if (this.hivePos && this.site.siteSFMode == 1) {
            this.map.addHiveCircle(this.hivePos[0], this.hivePos[1]);
          } else {
            this.map.removeHiveCicle();
          }
        }
      },
    },
    currentIndex: {
      handler(n) {
        this.map.selectOnePoint(n);
        if (this.missionInfo.flightParams.flightPath[this.currentIndex].heading == -9999) {
          this.currentPtHeading = 1;
        } else {
          this.currentPtHeading = 0;
        }
      },
    },
  },
  created() {
    this.resetMissionInfo();
    this.getUserCorporationList()
  },
  mounted() {
   
  },

  methods: {
    async getUserCorporationList(){
         let u = getUserInformation();
     if (u && u.userInformation && u.userInformation.userId) {
        this.userId = u.userInformation.userId;
        let { code, reason, data } = await getUserCorporationList();
        if (statusInclude(code)) {
          this.CPList = data;
          console.log(data)
        }
      }
    },
    // 当前航点的航向
    currentHeadingHandler(t) {
      if (t == 1) {
        this.missionInfo.flightParams.flightPath[this.currentIndex].heading = -9999;
      } else {
        this.missionInfo.flightParams.flightPath[this.currentIndex].heading = 0;
      }
    },
    // cesium 加载成功
    renderedCesium() {
      this.cesiumInstance = this.$refs.cesiumMap;
      if (this.site) {
        //   (this.site.siteMode, hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], true);
      }
      if (this.missionInfo?.flightParams?.flightPath && this.missionInfo.flightParams.flightPath.length > 0) {
        // this.cesiumInstance.updateRoute(this.missionInfo.flightParams.flightPath);
      }
    },
    // 没有发送文件
    noFileHandler() {},
    startDesign() {
      this.isMask = true;
      this.isUpDataByKML = false;
      this.createdModel = 1;
    },

    searchBySID(id) {
      this.stId = id;
      this.$nextTick(() => {
        this.$refs.missionGroup.getMissionList(id);
      });
    },

    resetMissionInfo() {
      this.missionInfo = {
        missionName: '',
        missionType: 1,
        UAVModel: 'M300',
        siteID: '',
        Gimbal1Type: 'H20T',
        flightParams: {
          GCSType: 1,
          cruiseAltitude: 120,
          cruiseSpeed: 10,
          cruiseGimbalPitch: -45,
          isGPhoto: false,
          flightPath: [],
        },
      };
    },
    siteListHandler(siteList) {
      this.siteList = siteList;
    },
    async getSiteList(pid) {
      this.siteList = [];
      const { code, reason, data } = await getSiteGroup(1, 1000, pid);
      if (statusInclude(code)) {
        for (let site of data.list) {
          if (site.siteID != this.site.siteID) {
            this.siteList.push(site);
          }
        }
      }
    },
    changeEndSite(siteID) {
      if (siteID) {
        this.setEndHiver(siteID);
      } else {
        // this.map.removeEndHiver();
        // this.map.removeDashPath();
        let hiveModel = null;
        if (this.site?.hiveInfo?.hiveModel) {
          hiveModel = this.site.hiveInfo.hiveModel;
        }
        if (this.site) {
          this.map.addEndHiveMarker(this.site.siteMode, hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], true);
          this.map.addEndHiveToMissionPath();
        }
      }
    },
    // 删除成功之后清空地图
    clearMap() {
      this.isOpen = false;
      this.removeMapAll();
    },
    /**
     * @description: 任务名称查询
     * @param {*} query
     * @return {*}
     * @author: 篆愁君
     */
    retrieve(query) {
      this.query = query;
    },

    screen(id) {
      this.stId = id;
    },

    mapRendered() {
      this.map = this.$refs.map;
    },

    closeHandler() {
      this.removeMapAll();
      this.$refs.missionGroup.currentMission(-1);
      this.isOpen = false;
      this.isEdit = false;
    },

    // 清楚地图所有
    removeMapAll() {
      this.map.setMapClickMode(null);
      this.map.removeMissionPath();
      this.map.removeHiver();
      this.map.removeEndHiver();
      this.map.removeDashPath();
      this.map.removeHiveCicle();
    },

    emitMissionName(name) {
      this.missionInfo.missionName = name;
    },

    // 地图新增航点或移动了航点，index>total为新增
    changePostionHandler(obj) {
      const { middlePt, index, latitude, longitude, distance } = obj;
      const point = {};
      point.heading = -9999;
      this.currentIndex = index; //记录操作点下标
      if (middlePt === true) {
        const { gimbalPitch, altitude, actions, isPhoto, speed = 0 } = JSON.parse(JSON.stringify(this.missionInfo.flightParams.flightPath[index]));
        point.gimbalPitch = gimbalPitch;
        point.altitude = altitude;
        point.isPhoto = isPhoto;
        point.actions = actions;
        point.speed = speed;
        this.handleRefreshMarkerInformation(index + 1, false, Object.assign(point, { index, latitude, longitude }));
      } else if (middlePt === false) {
        //添加新航点
        const { cruiseAltitude, cruiseSpeed, cruiseGimbalPitch, isGPhoto } = this.missionInfo.flightParams;
        point.gimbalPitch = cruiseGimbalPitch;
        point.altitude = cruiseAltitude;
        point.isPhoto = isGPhoto;
        point.speed = cruiseSpeed;
        point.actions = [];
        this.currentMarker = null;
        this.missionInfo.flightParams.flightPath.push(Object.assign(point, { index, latitude, longitude }));
      } else {
        //编辑航点
        this.missionInfo.flightParams.flightPath[index].latitude = latitude;
        this.missionInfo.flightParams.flightPath[index].longitude = longitude;
        this.$refs?.carousel.setActiveItem(index);
      }
      this.missionInfo.flightParams.flightPath.forEach((point, index) => (point.index = index));
      this.distanceChange(distance);
    },

    /**
     * @description: 操作数据下标
     * @param {Number} index
     * @param {Number or Boolean} del:  false插入  true删除
     * @param {Object} marker
     * @return {*} void
     * @author: 篆愁君
     */
    handleRefreshMarkerInformation(index, del, marker) {
      if (!del) {
        const { cruiseAltitude, cruiseSpeed, cruiseGimbalPitch, isGPhoto } = this.missionInfo.flightParams;
        marker.gimbalPitch = cruiseGimbalPitch;
        marker.altitude = cruiseAltitude;
        marker.isPhoto = isGPhoto;
        marker.speed = cruiseSpeed;
        marker.actions = [];
        if (isGPhoto) {
          marker.actions.push({
            action: 5,
            name: '拍照',
            type: 2,
            index: 1,
            actionParams: 0,
          });
        }
        this.currentMarker = null;
        this.missionInfo.flightParams.flightPath.splice(index, 0, marker);
        this.$nextTick(() => {
          this.currentIndex++;
          this.$refs.carousel.setActiveItem(this.currentIndex);
        });
      } else {
        this.delMarkerByIndex(index);
        this.missionInfo.flightParams.flightPath.splice(index, 1);
        this.missionInfo.flightParams.flightPath.forEach((marker, index) => (marker.index = index));
        this.msgGroup[2].value = this.missionInfo.flightParams.flightPath.length;
        this.currentIndex--;
        if (this.currentIndex == -1 && this.missionInfo.flightParams.flightPath.length > 0) {
          this.$nextTick(() => {
            this.currentIndex = 0;
            this.$refs.carousel.setActiveItem(this.currentIndex);
          });
        }
      }
    },

    //地图错误信息（如航线的航点设置过长）
    errorMsgHandler(msg) {
      this.$message({ type: 'error', message: msg });
    },
    //选中的航点
    selectedHandler(index) {
      this.currentIndex = index;
      if (this.$refs.carousel) {
        this.$refs.carousel.setActiveItem(index);
      }
    },
    /**
     * @description: 计算航线长度
     * @param {*} distance
     * @return {*}
     * @author: 篆愁君
     */
    distanceChange(distance) {
      this.msgGroup[0].value = distance;
      if(this.missionInfo?.flightParams?.flightPath.length){
       // 航线的长度加上起降站点的高度(首末航点与起降点高度差)
      let topStart =  Math.abs(this.missionInfo.flightParams.flightPath[0].altitude- this.site.siteAltitude);
      let endSite = this.endSite?this.endSite:this.site;
      let topEnd = Math.abs(this.missionInfo.flightParams.flightPath[this.missionInfo.flightParams.flightPath.length-1].altitude- endSite.siteAltitude);
      this.msgGroup[1].value = parseFloat(((distance+topStart + topEnd) / this.missionInfo.flightParams.cruiseSpeed).toFixed(1));
      this.msgGroup[2].value = this.missionInfo.flightParams.flightPath.length;
      }else{
          this.msgGroup[1].value = 0;
      this.msgGroup[2].value = 0;
      }
    },

    // 根据下标删除地图是上某个航点
    delMarkerByIndex(index) {
      this.map.delPathMarkerByIndex(index);
    },

    // 根据下标修改航点位置
    changeMarkerByIndex(index, lng, lat) {
      this.map.setMarkerPostionAndPath(index, lng, lat);
    },

    /**
     * @description: 设置飞行航点基础参数
     * @param {Object} e
     * @return {*} void
     * @author: 篆愁君
     */
    setMarkerParam(e) {
      const { v, uKey } = e;
      this.missionInfo.flightParams.flightPath[this.currentIndex][uKey.split('.')[1]] = v;
    },

    /**
     * @description: 获取当前marker点位信息
     * @param {*}
     * @return {*}
     * @author: 篆愁君
     */

    getCurrentMarker(marker) {
      this.currentMarker = marker; //深拷贝
      this.isMotion = true;
    },

    /**
     * @description: 删除航点动作
     * @param {*}
     * action: 5
         actionParams: 0
     * @return {*}
     * @author: 篆愁君
     */
    deleteAction(e) {
      console.log(e);
      this.missionInfo.flightParams.flightPath[this.currentIndex].actions.splice(e, 1);
      this.isDragEnd();
    },

    /**
     * @description: 设置航点动作参数
     * @param {*}
     * @return {*}
     * @author: 篆愁君
     */
    setActionValue(e) {
      const { index, v } = e;
      this.missionInfo.flightParams.flightPath[this.currentIndex].actions[index].actionParams = v;
    },

    /**
     * @description: 设置航点动作列表是否可操作
     * @param {*} isEnable
     * @return {*}
     * @author: 篆愁君
     */
    setEnable(isEnable) {
      this.isDisabled = isEnable;
    },

    // 根据站点id获取站点
    async getSiteById(id) {
      let { code, data } = await getSiteDetail(id);
      return data;
    },

    /**
     * @description: 获取currentMarker index
     * @param {Number} i
     * @return {*} void
     * @author: 篆愁君
     */
    getIndex(i) {
      //  if (!i) return  // 为0时return导致地图不高亮 xxf @2021.12.03
      this.currentIndex = i;
    },

    /**
     * @description: 修改航点动作顺序
     * @param {*} e
     * @return {*} void
     * @author: 篆愁君
     */
    isDragEnd(e) {
      this.missionInfo.flightParams.flightPath[this.currentIndex].actions.forEach((action, index, self) => {
        action.index = index;
      });
    },

    /**
     * @description: 选择任务新建类型
     * @param {*} val
     * @return {*}
     * @author: 篆愁君
     */
    setNewMissionType(val) {
      const { type, isMask } = val;
      this.isMask = isMask;
      type === 2 && (this.isUpDataByKML = true);
      this.dialogVisible = true;
    },

    readFile(flightParams) {
      this.createdModel = 2;
      this.missionInfo.flightParams = flightParams;
      
      if (flightParams && flightParams.flightPath && flightParams.flightPath.length > 0) {
        this.transferPath();
      }
    },
    /**
     * @description 将站点实例化到地图
     * @param KB 输入框中的基础信息
     * @return void
     */
    puttingMissionInfo(KB) {
      
      this.removeMapAll();
      this.STOPSAVEMSG = null;
      this.site = null;
      if (this.createdModel == 1) {
        this.resetMissionInfo();
      }

      this.msgGroup.forEach((e) => (e.value = 0));
      const { mission, type } = KB;
      if (!mission) return this.$message({ type: 'warning', message: '请完善任务基础信息' });
      //合并基础信息
      for (let key in mission) {
        this.missionInfo[key] = mission[key];
      }
      this.dialogVisible = false;
      this.isOpen = true;
      if(KB.mission.missionType===2){ // 二维扫图
        this.scanMode=true
        return  false
      }
      this.$nextTick(async () => {
        let siteData = await this.getSiteById(mission.siteID);
        console.log(siteData);
        this.site = siteData;
        // 起飞站点
        if (siteData && siteData.siteLocation) {
          this.hivePos = siteData.siteLocation;
          if (this.hivePos) {
            this.map.addHiveMarker(siteData.siteMode, siteData.hiveModel, this.hivePos[0], this.hivePos[1], true);
            this.map.addEndHiveMarker(siteData.siteMode, siteData.hiveModel, this.hivePos[0], this.hivePos[1], true);
            if (siteData.siteSFMode == 1) {
              // 孤岛模式
              this.map.addHiveCircle(this.hivePos[0], this.hivePos[1]);
            } else {
              this.setEndHiver(mission.endSiteID);
            }
          }
        }
        this.map.setMapClickMode(CLICKMODE.route);

        if (this.missionInfo?.flightParams?.flightPath) {
          // 导入的航线
          this.currentIndex = this.missionInfo.flightParams.flightPath.length-1
          this.map.setMissionPath(this.missionInfo.flightParams.flightPath, CLICKMODE.none, this.hivePos);
          this.map.addEndHiveMarker(this.site.siteMode, this.site.hiveModel, this.site.siteLocation[0], this.site.siteLocation[1], true);
          this.map.addEndHiveToMissionPath();
          this.$nextTick(() => {
            if (this.missionInfo.flightParams.flightPath.length > 0) {
              this.map.selectOnePoint(this.missionInfo.flightParams.flightPath.length - 1);
              this.map.fitMissionPath();
              this.STOPSAVEMSG = this.map.isRouteDistanceMatch(this.hivePos, this.missionInfo.flightParams.flightPath); //判断距离
            }
            if (this.STOPSAVEMSG) {
              this.$message.error(this.STOPSAVEMSG);
            }
          });
        }
      });
    },

    // 添加降落机库和连线
    async setEndHiver(endSiteID) {
      this.endSite = null;
      let res = await getSiteDetail(endSiteID);
      if (res.data) {
        let endSiteData = res.data;
        this.endSite = res.data;
        if (endSiteData && endSiteData.siteLocation) {
          this.endHivePos = endSiteData.siteLocation;
          if (this.endHivePos) {
            let siteMode = endSiteData.siteMode;
            let hiveModel = null;
            if (endSiteData?.hiveInfo?.hiveModel) {
              hiveModel = endSiteData.hiveInfo.hiveModel;
            }
            this.map.addEndHiveMarker(siteMode, hiveModel, this.endHivePos[0], this.endHivePos[1], false);
            this.map.addEndHiveToMissionPath();
          }
        }
      }
    },
    /**
     * @description: 编辑任务
     * @param {*}
     * @return {*}
     * @author: 篆愁君
     */
    editMission(data) {
      this.isOpen = true;
      this.isEdit = true;
      this.map.setMapClickMode(CLICKMODE.route);
      this.map.setMissionPathEditAble(true);
      if (this.missionInfo && this.missionInfo.flightParams && this.missionInfo.flightParams.flightPath && this.missionInfo.flightParams.flightPath.length > 0) {
        this.map.selectOnePoint(this.missionInfo.flightParams.flightPath.length - 1);
      }
    },

    // 复制
    async copyMission(data) {
      if (this.missionInfo) {
        let addData = JSON.parse(JSON.stringify(this.missionInfo));
        addData.missionName = addData.missionName + '_copy';
        var { code, reason } = await insertMission(addData);
        if (statusInclude(code)) {
          this.$message({ type: 'success', message: '复制成功' });
          this.removeMapAll();
          this.isOpen = false;
          this.isEdit = false;
          this.$refs.missionGroup.hideActiveToolBar();
          this.$refs.missionGroup.getMissionList();
          return;
        }
      }
    },

    exportMission(){
      this.dialogDownloadVisible=true;
    },
    toDownLoad(){
      if(this.fileDownloadType==1){
         this.$refs.downFile.kmlDown(this.missionInfo)
      }else if(this.fileDownloadType==2){
          this.$refs.downFile.jsonDown(this.missionInfo)
      }
       this.dialogDownloadVisible=false;
    },

    /**
     * @description: 获取任务名称列表
     * @param {*} data
     * @return {*}
     * @author: 篆愁君
     */
    getChildEmit(data) {
      this.missionNameGroup = data;
    },

    transferPath() {
      if (this.missionInfo?.flightParams?.flightPath && this.missionInfo.flightParams.flightPath.length > 0) {
        this.missionInfo.flightParams.flightPath.forEach((point, index) => {
          point.actions || (point.actions = []);
          point?.actions?.forEach((item, index) => {
            
            const a = this.activeMenu[item.action - 1];
            Object.assign(item, a);
            switch (item.action) {
              case 1:
                item.min = 5;
                item.max = 60; //5分钟
                item.unit = 's';
                break;
              case 2:
                break;
              case 3:
                break;
              case 4:
                item.min = -90;
                item.max = 0;
                item.unit = '°';
                break;
              case 5:
                break;
              case 6:
                item.min = -180;
                item.max = 180;
                item.unit = '°';
                break;
              case 7:
                item.min = 1;
                item.max = 30;
                item.unit = '倍';
                break;
                 case 8:
                item.min = 1;
                item.max = 30;
                item.unit = '°';
                break;
                 case 9:
                item.min = 1;
                item.max = 30;
                item.unit = '°';
                break;
                 case 10:
                item.min = 1;
                item.max = 30;
                item.unit = '秒';
                break;
                 case 11:
                item.min = 1;
                item.max = 30;
                item.unit = '米';
                break;
                 case 12:
                item.min = 1;
                item.max = 30;
                item.unit = '';
                break;
              default:
                break;
            }
          });
        });
      }
    },
    scanCancel(msg){
      this.scanMode= false;
      if(msg){
         this.$message({ type: 'success', message: msg });
      }
      this.missionDetailHandler(null) 
    },
    // 点击选中一个任务
    async missionDetailHandler(missionDetail) {
      this.site = null;
      this.removeMapAll();
       this.isOpen = false;
        this.isEdit = false;
      if (!missionDetail) {
        return;
      }
      if(missionDetail.missionType===2){
        this.missionInfo = missionDetail
        this.scanMode=true
        return false;
      }
      for (let key in missionDetail) {
        this.missionInfo[key] = missionDetail[key];
      }
      this.transferPath();
      this.currentIndex = this.missionInfo.flightParams.flightPath.length - 1;
      if (missionDetail.siteID) {
        let siteData = await this.getSiteById(missionDetail.siteID);
        this.site = siteData;
        console.log(siteData);
        let siteMode = siteData.siteMode;
        this.getSiteList(siteData.PID);
        let hiveModel = null;
        if (siteData?.hiveInfo?.hiveModel) {
          hiveModel = siteData.hiveInfo.hiveModel;
        }
        if (siteData && siteData.siteLocation) {
          this.hivePos = siteData.siteLocation;
          this.map.addHiveMarker(siteMode, hiveModel, siteData.siteLocation[0], siteData.siteLocation[1], true);
          this.map.addEndHiveMarker(siteMode, hiveModel, siteData.siteLocation[0], siteData.siteLocation[1], true);
        }
      } else {
        this.$message({ type: 'warning', message: '站点ID缺失！' });
      }
      // 绘制地图航线
      this.map.setMissionPath(this.missionInfo.flightParams.flightPath, CLICKMODE.none, this.hivePos);
      this.map.addEndHiveToMissionPath();
      this.$nextTick(() => {
        this.map.fitMissionPath();
      });
    },

    /**
     * @description: 设置航线参数
     * @param {Number} v
     * @param {String} uKey
     * @return {*}
     * @author: 篆愁君
     */
    setLineParam({ v, uKey }) {
      this.missionInfo.flightParams[uKey] = v;
    },
    /**
     * @description: 输入点坐标
     * @param {*} marker
     * @return {*}
     * @author: 篆愁君
     */
    modifyCoordinates(marker) {
      const { longitude, latitude } = marker;
      this.changeMarkerByIndex(this.currentIndex, Number(longitude), Number(latitude));
    },

    /**
     * @description: 微调四方盘
     * @param {Object} e marker
     * @return {*}
     * @author: 篆愁君
     */
    trimmingLngLat(e) {
      let {
        marker: { index },
        item: { key, type },
      } = e;
      let v;
      let value = this.$refs[key][index].value;
      switch (type) {
        case 1:
          v = value + this.compensate;
          break;
        case 2:
          v = value + this.compensate;
          break;
        case 3:
          v = value - this.compensate;
          break;
        case 4:
          v = value - this.compensate;
          break;
        default:
          this.$message.error('参数错误');
          break;
      }
      this.missionInfo.flightParams.flightPath[index][key] = +v.toFixed(6);
      const { longitude: lng, latitude: lat } = this.missionInfo.flightParams.flightPath[index];
      this.changeMarkerByIndex(index, lng, lat);
    },

    /**
     * @description: Right toolBar 功能项
     * @param {Object} e
     * @return {*} void
     * @author: 篆愁君
     */
    async paramFun(e) {
      switch (e) {
        case 'save':
           this.STOPSAVEMSG = this.map.isRouteDistanceMatch(this.hivePos, this.missionInfo.flightParams.flightPath); //判断距离
          if (this.STOPSAVEMSG) {
            this.$message.error(this.STOPSAVEMSG);
            return;
          }
          const missionInformation = JSON.parse(JSON.stringify(this.missionInfo));
          if (missionInformation.flightParams.flightPath.length === 0) return this.$message({ type: 'warning', message: '任务不能为空' });
          missionInformation.flightParams.flightPath.forEach((point) => {
            if (point?.actions.length === 0) {
              delete point.actions;
            } else {
              const actions = point.actions.map((e, index) => {
                const obj = {
                  index: index,
                  action: e.action,
                };
                e.actionParams !== '' && (obj.actionParams = e.actionParams);
                return obj;
              });
              point.actions = actions;
            }
          });
          const { code, reason } = this.isEdit ? await updateMission(missionInformation) : await insertMission(missionInformation);

          if (!statusInclude(code)) return this.$message.error(`新增任务失败：${reason}`);
          this.$message({ type: 'success', message: '操作成功' });
          this.isOpen = false;
          this.isEdit = false;
          this.removeMapAll();
          this.$refs.missionGroup.hideActiveToolBar();
          this.$refs.missionGroup.getMissionList();
          break;
        case 'delete':
          this.handleRefreshMarkerInformation(this.currentIndex, true, '');
      }
    },
  },
};
</script>

<style lang="scss">
.el-switch__label {
  color: var(--color-primary);
}
.mission-plan-wrap {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  overflow: hidden;

  .mission-list-components {
    width: 280px;
    padding: 10px;
    background: var(--el-fill-color-blank);
    border: 1px solid var(--main-border-color);
    height: calc(100vh - 112px);
  }

  .content-map-wrapper {
    flex: 1;
    position: relative;
    height: calc(100vh - 112px);
    .reference {
      position: absolute;
      top: 10px;
      left: 0px;
      z-index: 100;
      width: 200px;
    }
    .map-set {
      position: absolute;
      top: 10px;
      right: 0px;
      z-index: 100;
      width: 58px;
      img {
        &:active {
          transform: scale(0.85);
        }
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
  }

  .right-param-wrapper {
    width: 0px;
    height: calc(100vh - 112px);
    background: var(--el-fill-color-blank);
    box-sizing: border-box;
    border: 1px solid var(--main-border-color);
    transition: width 0.3s;
    > div {
      height: 100%;
    }
    .switch-button-wrapper {
      width: 280px;
      height: 40px;
      border-bottom: 1px solid var(--main-border-color);
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;

      > .switch-mission-param {
        height: 28px;
      }
    }
    .air-route-param-wrapper {
      width: 280px;
    }
    .waypoints-param-wrapper {
      min-height: 400px;
    }

    .el-carousel {
      width: 280px;
      height: calc(100vh - 336px);

      .el-carousel__container {
        height: 100%;
        min-height: 625px;

        .el-carousel__arrow {
          top: 10px;
          transform: translate(0, 0);
        }

        .el-carousel__item {
          padding: 10px;
          height: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .waypoint-index {
            height: 36px;
            line-height: 36px;
            padding: 0;
            margin: 0;
            text-align: center;
            color: #f40;
          }

          .coordinates-ctrl-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .el-form {
              width: 146px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .el-form-item {
                margin: 9px 0;

                .el-form-item__content {
                  .el-input {
                    .el-input__inner {
                      padding: 0 8px;
                    }
                  }
                }
              }
            }

            .coordinates-wrapper {
              flex: 1;
              padding-left: 14px;
              height: 100%;
              color: var(--color-primary);
            }
          }

          .photo-btn {
            padding: 10px;
            span {
              margin-bottom: 10px;
              font-size: 14px;
              color: var(--color-primary);
            }
            display: flex;
            flex-direction: column;
          }

          .action-list-wrap {
            flex: 1;
            margin-top: 10px;
            padding: 10px;
            background: var(--el-fill-color-blank);
            overflow: auto;
            border: 1px solid var(--main-border-color);
            box-sizing: border-box;
            .action-list {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
    &.open {
      width: 280px;
    }
    .motion-area-wrapper {
      .el-page-header {
        background: var(--el-popper-background);
        padding: 10px;
        .el-page-header__left {
          color: var(--color-primary);
        }
        .el-page-header__content {
          color: var(--color-primary);
          font-size: 16px;
        }
      }
      .action-list-wrap {
        padding: 0 10px 10px 10px;
        .action-list {
          padding: 0;
          margin: 0;
          .action-item {
            background: #f40;
          }
        }
      }
      .el-divider {
        .el-divider__text {
          background: var(--el-popper-background);
          color: var(--color-primary);
        }
      }
    }
  }
}
</style>
