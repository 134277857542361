<template>
  <div class="btn-slider">
    <div class="num-item">
      <div class="num-title">
        <div class="num-name">
          <img v-if="icon" class="num-icon" :src="icon">
          {{ title }}
        </div>
        <div v-if="unit" class="num-value">
          {{ dataValve }}<span>{{ unit }}</span>
        </div>
      </div>
      <div v-if="step >= 0.1&&visible" class="num-slider">
        <div class="icon-c" @click="minusFun">
          <img class="num-icon" src="@/assets/images/mission/task/minus.svg">
        </div>
        <a-slider
          v-model="dataValve"
          :min="min"
          :max="max"
          :step="step"
          :tooltip-visible="false"
          @change="change"
        />
        <div class="icon-c" @click="plusFun">
          <img class="num-icon" src="@/assets/images/mission/task/plus.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'PlanSlider',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    value: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    min: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    step: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      dataValve: ''
    };
  },
  watch: {
    value(val) {
      this.dataValve = Number(val.toFixed(2));
    },
    dataValve(val) {
      this.$emit('watchVal', val);
    }
  },
  created() {
    this.dataValve = this.value;
  },
  methods: {
    change() {
      this.$emit('change', this.dataValve);
    },
    minusFun() {
      if (Number(this.dataValve) > Number(this.min)) {
        this.dataValve = Number((Number(this.dataValve) - Number(this.step)).toFixed(2));
        this.change();
      }
    },
    plusFun() {
      if (Number(this.dataValve) < Number(this.max)) {
        this.dataValve = Number((Number(this.dataValve) + Number(this.step)).toFixed(2));
        this.change();
      }
    }
  }
};
</script>

<style lang="scss">
.btn-slider {
  .num-slider {
    height: 32px;
    .ant-slider {
      margin: 0;
      padding-top: 13px;
    }
    .ant-slider-rail {
      background: rgba(255, 255, 255, 0.15);
    }
    .ant-slider-track {
      background: #36C6FB;
    }
    .ant-slider-handle {
      background: #36C6FB;
      border-color: #36C6FB;
    }
  }
}
</style>

<style lang="scss" scoped>
.btn-slider {
  .num-item {
    padding: 12px 0;
  }
  .num-title {
    display: flex;
    height: 24px;
    line-height: 24px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    .num-icon {
      margin-right: 8px;
      width: 14px;
    }
    .num-name {
      font-size: 14px;
      color: rgba(205,205,205,1);
      display: flex;
      align-items: center;
    }
    .num-value {
      font-size: 14px;
      color: rgba(255,255,255,1);
      span {
        width:13px;
        font-size: 14px;
        color: rgba(255,255,255,0.6);
        padding-left: 4px;
      }
    }
  }
  .num-slider {
    width: 200px;
    margin: 0 auto;
    position: relative;
    .icon-c {
      width: 22px;
      height: 22px;
      background :rgba(57,59,63,1);
      border-radius: 2px;
      position: absolute;
      left: -30px;
      top: 5px;
      cursor: pointer;
      img{
        width: 22px;
      }
      &:last-child {
        left: auto;
        right: -30px;
      }
      &:hover {
        background-color: #36C6FB;;
      }
    }
  }
}
</style>
