<template>
  <div class="exec-mask-wrapper" @click.stop="enableMask">
    <div class="element-wrapper">
      <el-tooltip class="item" effect="dark" v-for="t in crmGroup" :key="t.type" :content="t.content" placement="top-start">
        <i :class="[t.type === 1 ? `${t.icon}` : `iconfont ${t.icon}`]" @click.stop="setValue(t.type)"></i>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageCRMMission',
  data() {
    return {
      crmGroup: [
        {
          type: 1,
          content: '新建任务',
          icon: 'el-icon-document-add',
        },
        {
          type: 2,
          content: '导入任务',
          icon: 'icon-daoru',
        },
      ],
    };
  },
  methods: {
    enableMask() {
      this.$emit('enableMask');
    },

    setValue(type) {
      // if (type === 2) return this.$message({ type: 'warning', message: '功能待开放,尽请期待' });
      this.$emit('setValue', { type, isMask: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.exec-mask-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: #22252a99;
  z-index: 999;
  .element-wrapper {
    padding: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #34373d;
    background: #34373d;
    i {
      margin: 20px;
      font-size: 60px;
      padding: 20px 10px;
      border: 4px solid #34373d;
      color: #fff;
      background: #22252a;
      cursor: pointer;
    }
    i:first-child {
      margin-right: 60px;
    }
    i:hover {
      background: #fff;
      color: #22252a;
    }
    i:active {
      color: #f40;
    }
  }
}
</style>
