<template>
  <el-form class="mode-choose">
    <el-form-item label=''>
      <el-select style="width:100%" v-model='siteID' @change="changeSite" clearable placeholder="请选择降落站点">
        <el-option v-for="site in siteList" :key="'site_'+site.siteID" :value="site.siteID" :label="site.siteName"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'ModeChoose',
  props: {
    net: {
      type: Boolean,
      default() {
        return false
      }
    },
    siteList: {
      type: Array,
      default() {
        return []
      }
    }

  },
  data() {
    return {
      siteID: ''
    }
  },
  created() {
  },
  methods: {
    changeHandler(t) {
      this.$emit('changeSingleMode', t)
    },
    changeSite(siteId) {
      this.$emit('changeSite', siteId)
    }
  },
};
</script>

<style lang="scss" scoped>
.mode-choose {
  padding: 0px 10px;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>
